import { useNavigate } from "react-router-dom"

import {
  useDeleteIntegration,
  useInstallIntegration,
  usePatchIntegration
} from "@/api/useIntegrations"
import { Form, Modal } from "@/atoms"
import { INTEGRATION_VENDORS } from "@/const/vendors-constants"
import { useCustomFunctionsForm } from "@/hooks/useCustomFunctionsForm"
import { useTranslation } from "@/hooks/useTranslation"
import { RemoveConfirmModal } from "@/pages/IntegrationsPage/components/molecules/RemoveConfirmModal"

import { WebhookAPIProps } from "./useWebhookAPI.types"

export const useWebhookAPI = (props: WebhookAPIProps) => {
  const { initialValues, messageApi } = props
  const params = { vendor: INTEGRATION_VENDORS.webhook }
  const webhookId = initialValues?.id
  const formId = webhookId || `${params.vendor}-form`
  const installer = useInstallIntegration(params)
  const updater = usePatchIntegration(params)
  const remover = useDeleteIntegration(params)
  const { focusRef, isDirty, setIsDirty, onFieldsChange } =
    useCustomFunctionsForm()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [modal, modalContext] = Modal.useModal()
  const [form] = Form.useForm()

  const getBackToIntegrationUrl = (configTab?: boolean) =>
    `/integrations/${params.vendor}${configTab ? "?tab=configuration" : ""}`

  const backToIntegration = (configTab?: boolean) =>
    navigate(getBackToIntegrationUrl(configTab))

  const messagePrefix = "integrations.webhook.messages"

  const onFinish = async (values: Record<string, any>) => {
    const prefix = `${messagePrefix}.${webhookId ? "update" : "add"}`

    try {
      const payload = {
        data: { webhook: webhookId ? { id: webhookId, ...values } : values }
      }

      if (webhookId) await updater.mutateAsync(payload)
      else await installer.mutateAsync(payload)

      messageApi?.success(t(`${prefix}.success`, { name: values.name }))

      if (!webhookId) backToIntegration(true)
      else setIsDirty(false)
    } catch (error) {
      messageApi?.error(t(`${prefix}.failed`))
    }
  }

  const onRemove = async () => {
    const name = initialValues?.name || "Webhook"
    const prefix = `${messagePrefix}.remove`

    modal.confirm(
      RemoveConfirmModal({
        t,
        content: t(`${messagePrefix}.content`),
        onOk: async () => {
          try {
            await remover.mutateAsync(webhookId)

            messageApi?.success(t(`${prefix}.success`, { name }))
          } catch {
            messageApi?.error(t(`${prefix}.failed`, { name }))
          }
        }
      })
    )
  }

  const onReset = () => {
    form.resetFields()
    setIsDirty(false)
  }

  const isLoading = installer.isPending || updater.isPending

  return {
    focusRef,
    form,
    initialValues,
    formId,
    isDirty,
    isLoading,
    onFinish,
    onFieldsChange,
    onRemove,
    onReset,
    getBackToIntegrationUrl,
    backToIntegration,
    t,
    modalContext
  }
}
