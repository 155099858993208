import { lazy, Suspense } from "react"

import { useTranslation } from "@/hooks/useTranslation"
import { HeaderPage } from "@/organisms/HeaderPage"
import { LoaderFallback } from "@/pages/DiscoveryPage/components/molecules/LoaderFallback"
import { PageTemplate } from "@/templates/PageTemplate"

const ApiEndpointsTab = lazy(
  () => import("@/pages/DiscoveryPage/components/organisms/ApiEndpointsTab")
)

export const ApiEndpointsPage = () => {
  const { t } = useTranslation()

  return (
    <PageTemplate
      full
      header={<HeaderPage titleTranslationKey={t("apiEndpoints.title")} />}
    >
      <Suspense fallback={<LoaderFallback />}>
        <ApiEndpointsTab />
      </Suspense>
    </PageTemplate>
  )
}
