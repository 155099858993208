import cn from "classnames"
import { memo } from "react"

import { Area, Text } from "@/atoms"
import {
  getDateFromSeconds,
  getRelativeTimeFromNow
} from "@/helpers/date.helpers"
import { ExploitIconsGroup } from "@/icons"
import { getDefaultLabelFormatterForXAxis } from "../../../const/graph"

import styles from "./ExploitableIssuesOverTimeArea.module.scss"

import type {
  ExploitableIssuesOverTimeAreaComponent,
  ExploitableIssuesOverTimeAreaProps
} from "."

export const getColorClassName = (value: number) => {
  const isZero = value === 0

  return cn({
    [styles.success]: isZero,
    [styles.warning]: !isZero
  })
}

const ExploitableIssuesOverTimeArea: ExploitableIssuesOverTimeAreaComponent = ({
  data: dataSource
}) => {
  const documentStyle = getComputedStyle(document.documentElement)
  const successColor = documentStyle.getPropertyValue("--polar-green-5-color")
  const dangerColor = documentStyle.getPropertyValue("--dust-red-9-color")
  const neutralColor = documentStyle.getPropertyValue("--neutral-1-color")
  const data = [...dataSource].reverse()
  const isAllZero = data.every((item) => item.value === 0)
  const maxValue = Math.max(...data.map((item) => item.value))
  const mainColor = isAllZero ? successColor : dangerColor
  const annotations = data.reduce(
    (acc, curr, index, arr) => {
      if (curr.value === 0 && (index === 0 || arr[index - 1].value > 0))
        acc.push([])

      if (curr.value === 0) acc[acc.length - 1].push(curr)

      return acc
    },
    [] as ExploitableIssuesOverTimeAreaProps["data"][]
  )

  const config = {
    data,
    xField: "timestamp",
    yField: "value",
    colorField: "type",
    height: 273,
    line: {
      tickInterval: 1,
      color: ["green", "yellow"],
      style: { stroke: mainColor, lineWidth: isAllZero ? 3 : 1 }
    },
    xAxis: {
      ...getDefaultLabelFormatterForXAxis()
    },
    yAxis: {
      tickCount: 5,
      max: Math.round(maxValue * (maxValue < 6 ? 10 - maxValue : 1.25)),
      label: {
        formatter: (value: string) => {
          if (Number(value) % 1 === 0) return value
        }
      }
    },
    annotations: annotations.map((arr) => {
      return {
        type: "regionFilter",
        start: [arr[0].timestamp, 1] as [number, number],
        end: [arr[arr.length - 1].timestamp, -2] as [number, number],
        color: successColor
      }
    }),
    areaStyle: () => {
      return {
        lineWidth: 3,
        fill: `l(270) 0:${neutralColor} 1:${mainColor}`
      }
    }
  }

  return (
    <Area
      tooltip={{
        domStyles: { "g2-tooltip": { padding: "6px 0px" } },
        customContent: (_: any, data: any) => {
          const { value, timestamp } = data[0]?.data || {}
          const colorClassName = getColorClassName(value)

          return (
            <div className={styles.tooltip}>
              <ExploitIconsGroup.IN_THE_WILD
                className={cn(
                  styles.exploiteIcon,
                  styles.smSizeExploiteIcon,
                  colorClassName
                )}
              />
              <div className={styles.labelTooltip}>
                Exploitable Issues
                <Text type="secondary">
                  {getRelativeTimeFromNow(getDateFromSeconds(timestamp))}
                </Text>
              </div>
              <div className={cn(styles.valueTooltip, colorClassName)}>
                <span>{Math.round(Number(value))}</span>
              </div>
            </div>
          )
        }
      }}
      {...config}
    />
  )
}

export default memo(
  ExploitableIssuesOverTimeArea,
  (prevProps, nextProps) => prevProps.data === nextProps.data
)
