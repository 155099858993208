import { useTranslation } from "@/hooks/useTranslation"
import { TitleStepDeployTab } from "@/pages/DeploymentsPage/components/molecules/DeployTab"
import { DownloadFileButton } from "@/pages/DeploymentsPage/components/molecules/DownloadFileButton"

import styles from "./DownloadStep.module.scss"

import type { DownloadStepComponent } from "."

export const DownloadStep: DownloadStepComponent = (props) => {
  const { t } = useTranslation()
  const { files, title, noError } = props

  return (
    <div className={styles.container}>
      <TitleStepDeployTab prefix={t("general.download")}>
        {title}
      </TitleStepDeployTab>
      {noError && (
        <>
          {files.map((file) => (
            <DownloadFileButton key={file.name} {...file} />
          ))}
        </>
      )}
    </div>
  )
}
