import { useEffect, useState } from "react"

import {
  APPLICATION_DRAWER_ID,
  CODE_REPOSITORY_DRAWER_ID,
  IMAGE_DRAWER_ID,
  TRIAGE_DRAWER_ID,
  VIRTUAL_MACHINE_DRAWER_ID
} from "@/organisms/Drawers/const"

export type DrawerId =
  | typeof APPLICATION_DRAWER_ID
  | typeof IMAGE_DRAWER_ID
  | typeof CODE_REPOSITORY_DRAWER_ID
  | typeof TRIAGE_DRAWER_ID
  | typeof VIRTUAL_MACHINE_DRAWER_ID

interface State {
  open?: undefined | false
  drawerId?: DrawerId
  recordId?: string
}

export type RowClick = (attr: { key: string }, drawerId: DrawerId) => void

export type ActionOpenDrawer = (drawerId: DrawerId, key: string) => void

export const useLinkDrawerTooltip = (props: { onRowClick?: RowClick }) => {
  const [state, setState] = useState<State>({})
  const { open } = state

  useEffect(() => {
    if (open === false) {
      const { drawerId, recordId } = state

      if (!drawerId || !recordId) return

      setState({})
      setTimeout(() => {
        props.onRowClick?.({ key: recordId }, drawerId)
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const openDrawer = (drawerId: DrawerId, recordId: string) => {
    setState(() => ({ drawerId, recordId, open: false }))
  }

  return { open, openDrawer }
}
