import cn from "classnames"

import { TEASERS } from "@/const/teaser.constants"

import { useTeasers } from "@/hooks/useTeasers"
import { useTranslation } from "@/hooks/useTranslation"
import { Funnel } from "@/organisms/Funnel"
import { useFunnelTriage } from "."
import { getColorsByIndex } from "./helpers"

import styles from "./FunnelTriagePage.module.scss"

import type { FunnelTriagePageComponent } from "."

export const FunnelTriagePage: FunnelTriagePageComponent = (props) => {
  const { getData = useFunnelTriage } = props
  const [[context, { isLoading }], cards, isRuntime, contextFixable, isDemo] =
    getData()
  const { t } = useTranslation()
  const { size, classes = {} } = props
  const teaser = TEASERS.SCM_INSTALLED.codeRepoNotConnected(t)
  const [showTeaser] = useTeasers({
    teasers: {
      dataReadiness: true,
      scmInstalled: teaser,
      kortexInstalled: teaser
    }
  })

  return (
    <div className={cn(styles.container, classes.container)}>
      {!showTeaser && (
        <Funnel
          isDemo={isDemo}
          loading={isLoading || contextFixable?.[1].isLoading}
          size={size}
          numbers={context.map((query, index) => {
            const value = query.data?.data || 0

            if (!isRuntime && index === 1 && value > 2) {
              const allIssues = context[0].data?.data || 0
              const serveIssues = context[2].data?.data || 0

              if (value === allIssues) {
                const nextValue = Math.round((allIssues - serveIssues) / 2)

                if (nextValue >= serveIssues) return nextValue
              }
            }

            return value
          })}
          fixableNumbers={
            isDemo
              ? (contextFixable?.[0] || []).map(
                  (query) => query.data?.data || 0
                )
              : []
          }
          cards={cards}
          getColorsByIndex={getColorsByIndex}
        />
      )}
    </div>
  )
}
