import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useHttp } from "@/hooks/useHttp"

import type { Config, Params } from "@/hooks/useHttp"
import type { StatisticsResponse } from "./useStatistics.types"

export const useGetStatistics = <T = StatisticsResponse, S = T>(
  params: Params<{ needle?: string } & Record<string, any>>,
  config?: Config<T, S>
) => {
  const { needle = "", ...restParams } = params
  const context = useHttp<T, S>(
    `${RESOURCES_NAMES.STATISTICS}${needle}`,
    restParams,
    config
  )

  return context
}
