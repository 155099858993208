import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"

import type { TFunction } from "@/hooks/useTranslation"

export const getItems = (t: TFunction) => {
  const TAB_KEYS = TAB_KEYS_BY_PAGE.SETTINGS

  return [
    { label: t("sections.organization") },
    { label: t("auth.title"), key: TAB_KEYS[2], admin: true },
    { label: t("users.title"), key: TAB_KEYS[0] },
    { label: t("sections.preferences") },
    { label: t("scopes.title"), key: TAB_KEYS[1] },
    { label: t("labels.title"), key: TAB_KEYS[9], admin: true },
    { label: t("sections.sast"), key: TAB_KEYS[8], admin: true },
    { label: t("sections.keys") },
    { label: t("apikeys.title"), key: TAB_KEYS[4] },
    { label: t("deploymentkeys.title"), key: TAB_KEYS[5], admin: true },
    { label: t("servicekeys.title"), key: TAB_KEYS[6], admin: true }
  ]
}
