import { Flex, Text } from "@/atoms"

import styles from "./LabelItemWebhook.module.scss"

import type { LabelItemWebhookProps } from "."

export const LabelItemWebhook = ({ values }: LabelItemWebhookProps) => (
  <Flex vertical>
    <Text strong>{values.name}</Text>
    {values.description && (
      <Text type="secondary">
        <Flex gap={4} className={styles.description}>
          <Text type="secondary">{values.description}</Text>
        </Flex>
      </Text>
    )}
  </Flex>
)
