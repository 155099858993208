import { Text } from "@/atoms"
import { ExclamationCircleOutlined } from "@/icons"

import styles from "./RemoveConfirmModal.module.scss"

import type { RemoveConfirmModalProps } from "."

export const RemoveConfirmModal = (props: RemoveConfirmModalProps) => ({
  className: styles.container,
  icon: <ExclamationCircleOutlined className={styles.warning} />,
  content: <Text>{props.content}</Text>,
  okText: props.t("remove"),
  okButtonProps: { danger: true },
  cancelText: props.t("cancel"),
  onOk: props.onOk
})
