import cn from "classnames"
import { Fragment } from "react"

import { Popover } from "@/atoms"

import styles from "./TitleDrawer.module.scss"

import type { TitleDrawerProps } from "."

export const TitleDrawer = ({
  title,
  subTitle,
  aside,
  icon,
  loading,
  style,
  popover,
  classes = {}
}: TitleDrawerProps) => {
  if (loading) {
    return <div className={cn(styles.loading)} />
  }

  const isLgSize = !!subTitle
  const classNameWithSubtitle = isLgSize && styles.withSubtitle
  const textSectionClassName = cn(styles.titleSection, classes.title)
  const Wrapper = popover ? Popover : Fragment
  const wrapperProps = popover
    ? ({ content: popover, trigger: "hover", placement: "bottomLeft" } as const)
    : {}

  return (
    <div style={style} className={cn(styles.container, classNameWithSubtitle)}>
      {icon && (
        <span className={cn(styles.icon, classNameWithSubtitle)}>{icon}</span>
      )}
      <Wrapper {...wrapperProps}>
        <div className={textSectionClassName}>
          {title}
          {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
        </div>
      </Wrapper>
      {aside && <div className={cn(styles.aside, classes.aside)}>{aside}</div>}
    </div>
  )
}
