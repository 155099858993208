import { DEFAULT_VALUES } from "@/pages/DeploymentsPage/const"
import { getDeployCommandString } from "@/pages/DeploymentsPage/helpers/deployment-command"
import { getLines as getLinesSecret } from "../EcsSecretDeployCommand/helpers"

export const getDefaultValues = (initialValues?: {
  environment?: string
  cloudFormationFile?: string
}) => {
  const { environment = DEFAULT_VALUES.ENVIRONMENT } = initialValues || {}

  return {
    key: Date.now().toString(),
    stackName: DEFAULT_VALUES.STACK_NAME,
    cloudFormationFile: DEFAULT_VALUES.CLOUDFORMATION_FILE,
    clusterARN: DEFAULT_VALUES.CLUSTER_ARN,
    deploymentKey: DEFAULT_VALUES.DEPLOYMENT_KEY,
    kortexSubnet: DEFAULT_VALUES.KORTEX_SUBNET,
    kortexSecurityGroup: DEFAULT_VALUES.KORTEX_SECURITY_GROUP,
    secretARN: DEFAULT_VALUES.SECRET_ARN,
    environment
  }
}

export const getLines = () => ({
  3: { text: "--parameter-override ClusterARN=", name: "clusterARN" },
  4: { text: "Environment=", name: "environment" },
  5: { text: "DeploymentKey=", name: "deploymentKey" },
  6: { text: "KortexDockerRegistrySecret=", name: "secretARN" },
  7: { text: "--capabilities CAPABILITY_NAMED_IAM" },
  8: { text: "KortexSubnet=", name: "kortexSubnet" },
  9: { text: "KortexSecurityGroup=", name: "kortexSecurityGroup" },
  ...getLinesSecret(["stackName", "cloudFormationFile"])
})

export const getDeployCommand =
  (fargate?: boolean) =>
  (
    values: ReturnType<typeof getDefaultValues>,
    defaultValues: ReturnType<typeof getDefaultValues>
  ) => {
    const lines = getLines()
    const commonKeys = ["1", "2", "3", "4", "5", "6"]

    const keys = fargate ? [...commonKeys, "8", "9", "7"] : [...commonKeys, "7"]

    return getDeployCommandString(
      {
        lines,
        values,
        defaultValues
      },
      keys
    )
  }
