import { Button, type ButtonProps } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { ConnectPlug } from "@/icons"

import styles from "./ConnectIntegrationPage.module.scss"

interface ConnectIntegrationFooterProps {
  onCancel: () => void
  isEnabled?: boolean
  isLoading?: boolean
  onSubmit?: (values: any) => void
  okText?: string
  okProps?: ButtonProps
}

export const ConnectIntegrationFooter: React.FC<
  ConnectIntegrationFooterProps
> = (props) => {
  const {
    okText,
    okProps,
    onSubmit,
    onCancel,
    isEnabled = false,
    isLoading = false
  } = props
  const { t } = useTranslation()
  const { t: tConnect } = useTranslation("translation", {
    keyPrefix: `integrations.connect`
  })

  return (
    <div className={styles.footerContainer}>
      <Button onClick={onCancel} disabled={isLoading}>
        {t("general.cancel")}
      </Button>

      <Button
        type="primary"
        icon={<ConnectPlug />}
        loading={isLoading}
        disabled={isEnabled}
        onClick={onSubmit}
        {...okProps}
      >
        {okText || tConnect("title")}
      </Button>
    </div>
  )
}
