import { getDefaultColumnParams } from "@/helpers/table.helpers"
import { ResourceCell } from "./ResourceCell"

import type { TypeResource } from "@/domain/issue"

export const getColumnsTable = (props: {
  onRowClick: (params: { key: string }, type: TypeResource) => void
  openPopover?: boolean
}): object[] => {
  return [
    {
      title: "Resources",
      width: "100%",
      render: ResourceCell(props),
      ...getDefaultColumnParams("resource")
    }
  ]
}
