import { RUNTIME_ID } from "@/const/default-values"
import {
  Exploite,
  HasFix,
  Runtime,
  SevereIssue,
  WarningOutlined
} from "@/icons"
import { DRAWERS_PARAM_NAME } from "@/organisms/Drawers/const"
import {
  getActiveTabFieldName,
  getIdsDrawer
} from "@/organisms/Drawers/hooks/useDrawer"
import { helpers } from "@/organisms/Funnel"

import type { TFunction } from "@/hooks/useTranslation"
import type { GetCardProps } from "."

export const getStringForCompare = (str: string) =>
  str.split(",").sort().join(",")

export const getQueryString = (
  params: Record<string, any> | undefined,
  searchParams: URLSearchParams
) => {
  if (!params) return { search: "", match: false }

  const query = new URLSearchParams(params)

  searchParams.delete("pageNumber")

  const orderBy = searchParams.get("orderBy")
  const ascending = searchParams.get("ascending")
  const drawers = searchParams.get(DRAWERS_PARAM_NAME)

  if (orderBy) query.set("orderBy", orderBy)
  if (ascending) query.set("ascending", ascending)
  if (drawers) {
    query.set(DRAWERS_PARAM_NAME, drawers)
    getIdsDrawer(searchParams).forEach((id) => {
      const value = searchParams.get(id)
      const tabId = getActiveTabFieldName(id)
      const tabValue = searchParams.get(tabId)

      if (value) query.set(id, value)
      if (tabValue) query.set(tabId, tabValue)
    })
  }

  if (query.size !== searchParams.size)
    return { search: query.toString(), match: false }

  if (query.size === 0) {
    return { search: query.toString(), match: true }
  }

  let isMatch = true

  query.forEach((value, key) => {
    if (
      getStringForCompare(value) !==
      getStringForCompare(searchParams.get(key) || "")
    )
      isMatch = false
  })

  return { search: query.toString(), match: isMatch }
}

export const getCards = (
  t: TFunction,
  searchParams: URLSearchParams,
  props: GetCardProps
) => {
  const keyPrefix = "triage.funnel.cards."
  let currentParams = {}

  return [
    {
      key: "open",
      params: { issueStatus: "open" },
      Icon: WarningOutlined
    },
    {
      key: RUNTIME_ID,
      params: props.runtime ? { runtime: "YES" } : {},
      Icon: Runtime
    },
    {
      key: "severe",
      params: { severity: "Critical,High" },
      Icon: SevereIssue
    },
    {
      key: "fixable",
      title: "funnel.cards.fixable",
      params: { fixability: "full,partial,has_fix" },
      Icon: HasFix
    },
    {
      key: "exploitable",
      title: "funnel.cards.exploitable",
      params: { exploitMaturity: "IN_THE_WILD,POC" },
      Icon: Exploite
    }
  ]
    .filter(({ key }) => {
      if (key === "fixable" && props?.isDemo) return false

      return true
    })
    .map(({ title, params, ...rest }) => {
      currentParams = { ...currentParams, ...params, ...props?.overrideParams }

      const { search, match } = getQueryString(currentParams, searchParams)

      return {
        link: `/triage/issues?${search}`,
        selected: match,
        title: title ? t(title) : t(`${keyPrefix}${rest.key}`),
        params: currentParams,
        ...rest
      }
    })
}

export const getColorsByIndex = (index: number) => {
  return helpers.getColorsByIndex(index)
}
