import cn from "classnames"

import { Col, Link, Text } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { ExploitIconsGroup, Runtime } from "@/icons"
import { StatisticDifference } from "@/molecules/StatisticDifference"
import { getCards } from "@/pages/TriagePage/components/organisms/FunnelTriagePage/helpers.ts"
import ExploitableIssuesOverTimeArea, {
  getColorClassName
} from "../../molecules/ExploitableIssuesOverTimeArea"
import CardDashboardTemplate from "../../templates/CardDashboardTemplate"

import styles from "./ExploitableIssuesOverTimeWidget.module.scss"

import type { ExploitableIssuesOverTimeWidgetComponent } from "."

export const ExploitableIssuesOverTimeWidget: ExploitableIssuesOverTimeWidgetComponent =
  ({ runtime, response, isLoading, exploitableFunnel }) => {
    const { data = [], weekAgoIndex = -1, score: scoreRes = 0 } = response || {}
    const score = exploitableFunnel || scoreRes
    const { t } = useTranslation()
    const lastScore = data[0]?.value
    const lastWeekScore = data[weekAgoIndex]?.value
    const diffFromLastWeek = lastWeekScore && lastScore - lastWeekScore
    const isNaN = Number.isNaN(Number(diffFromLastWeek))
    const colorClassName = getColorClassName(score)
    const noTrend = isNaN || weekAgoIndex === -1 || diffFromLastWeek === 0
    const isPositive = diffFromLastWeek < 0
    const funnelCards = getCards(t, new URLSearchParams(), {
      runtime: !!runtime
    })
    const loading = isLoading || runtime === undefined

    return (
      <CardDashboardTemplate
        title={t("Exploitable Issues Over Time")}
        wrap={false}
        loading={loading}
        contentPopover={
          runtime
            ? "Prioritizes Fixable and Exploitable Issues in Runtime exclusively."
            : "Prioritizes Fixable and Exploitable Issues exclusively."
        }
        teasers={{ dataReadiness: true }}
        noData={data.length === 0}
      >
        <Col className={styles.aside} flex="0 0 auto">
          <span>
            <Text type="secondary">Currently Exploitable</Text>
            {runtime && (
              <Text type="secondary" className={styles.runtimeIcon}>
                In <Runtime /> Runtime
              </Text>
            )}
            <Link
              className={styles.link}
              type="link"
              to={funnelCards[funnelCards.length - 1].link}
            >
              <span className={cn(styles.scoreAside, colorClassName)}>
                <span
                  className={cn(styles.iconAside, {
                    [styles.successIconAside]: score === 0,
                    [styles.warningIconAside]: score !== 0
                  })}
                >
                  <ExploitIconsGroup.IN_THE_WILD
                    className={cn(styles.exploiteIcon, colorClassName)}
                  />
                </span>
                <Text className={cn(styles.valueAside, colorClassName)}>
                  <span>{score}</span>
                </Text>
              </span>
            </Link>
            <StatisticDifference
              reverse
              isPositive={isPositive}
              noTrend={noTrend}
              isNeutral={noTrend}
              title={t(`dashboard.postureScoreWidget.differenceStatistic`)}
              value={isNaN ? "N/A" : Math.abs(diffFromLastWeek)}
            />
          </span>
        </Col>
        <Col flex="auto">
          <ExploitableIssuesOverTimeArea data={data} />
        </Col>
      </CardDashboardTemplate>
    )
  }
