import { DEFAULT_VALUES } from "@/pages/DeploymentsPage/const"
import { getDeployCommandString } from "@/pages/DeploymentsPage/helpers/deployment-command"

export const getDefaultValues = (initialValues?: Record<string, string>) => {
  const { cloudFormationFile = DEFAULT_VALUES.CLOUDFORMATION_FILE } =
    initialValues || {}

  return {
    key: Date.now().toString(),
    stackNameSecret: DEFAULT_VALUES.STACK_NAME,
    cloudFormationFileSecret: cloudFormationFile
  }
}

export const getLines = (
  names = ["stackNameSecret", "cloudFormationFileSecret"]
) => ({
  1: {
    text: "aws cloudformation deploy --stack-name",
    name: names[0],
    align: "space-between" as const
  },
  2: {
    text: "--template-file",
    name: names[1],
    align: "space-between" as const
  }
})

export const getDeployCommand = (
  values: ReturnType<typeof getDefaultValues>,
  defaultValues: ReturnType<typeof getDefaultValues>
) => {
  const lines = getLines()

  return getDeployCommandString({
    lines,
    values,
    defaultValues
  })
}
