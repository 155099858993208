import {
  DeployCommand,
  type CommonDeployCommandProps
} from "@/pages/DeploymentsPage/components/molecules/DeployCommand"
import { InlineField } from "@/pages/DeploymentsPage/components/molecules/InlineField"
import { REGCRED_CF_YAML_FILENAME } from "@/pages/DeploymentsPage/const"
import { getDefaultValues, getDeployCommand, getLines } from "./helpers"

export const EcsSecretDeployCommand = (props: CommonDeployCommandProps) => {
  const lines = getLines()

  return (
    <DeployCommand
      getDefaultValues={getDefaultValues}
      getDeployCommand={getDeployCommand}
      cloudFormationFile={REGCRED_CF_YAML_FILENAME}
      {...props}
    >
      {(props) => (
        <>
          <InlineField line={lines["1"]} {...props} />
          <InlineField noNullCharacter line={lines["2"]} {...props} />
        </>
      )}
    </DeployCommand>
  )
}
