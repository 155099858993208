import { DEFAULT_VALUES } from "@/pages/DeploymentsPage/const"
import {
  DEFAULT_END_LINE,
  getDeployCommandString,
  getTextForValue
} from "@/pages/DeploymentsPage/helpers/deployment-command"

export const getDefaultValues = (initialValues?: { environment?: string }) => {
  const { environment = DEFAULT_VALUES.ENVIRONMENT } = initialValues || {}

  return {
    key: Date.now().toString(),
    workDir: DEFAULT_VALUES.WORKDIR,
    pathKortexTGZ: DEFAULT_VALUES.KORTEX_TGZ_PATH,
    deploymentKey: DEFAULT_VALUES.DEPLOYMENT_KEY,
    environment
  }
}

export const getLines = () => ({
  1: { text: "cd", name: "workDir", align: "space-between" as const },
  2: {
    name: "pathKortexTGZ",
    text: "tar --no-overwrite-dir -xvf",
    align: "space-between" as const
  },
  3: {
    name: "environment",
    text: "sudo ./install_kortex.sh",
    align: "space-between" as const
  },
  4: { text: "sudo systemctl start komon koltan" },
  5: { text: "rm", name: "pathKortexTGZ", align: "space-between" as const }
})

export const getDeployCommand = (
  values: ReturnType<typeof getDefaultValues>,
  defaultValues: ReturnType<typeof getDefaultValues>
) => {
  const lines = getLines()
  const props = { lines, values, defaultValues }

  const first = getDeployCommandString(props, ["1"])
  const second = getDeployCommandString(props, ["2"])
  const third = getDeployCommandString(props, ["3"])
  const fourth = getDeployCommandString(props, ["4"])
  const fifth = getDeployCommandString(props, ["5"])
  const suffix = `${DEFAULT_END_LINE}&& `

  return `${first}${suffix}${second}${suffix}${third} ${getTextForValue(values.deploymentKey, defaultValues.deploymentKey)}${suffix}${fourth}${suffix}${fifth}`
}
