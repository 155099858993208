import cn from "classnames"

import { useCreateKey } from "@/api/useAuthKeys"
import { Text } from "@/atoms"
import { EVENTS } from "@/const/event.constants"
import { TAB_KEYS_BY_PAGE } from "@/const/tab.constants"
import { isKnownUserRole, isUserAdmin } from "@/domain/user"
import { useAuthKeys } from "@/pages/SettingsPage/application/auth/useAuthKeys"
import { getItems } from "@/pages/SettingsPage/helpers/menu"
import { AuthenticationMethodTab } from "../../organisms/AuthenticationMethodTab"
import { AuthKeysTab } from "../../organisms/AuthKeysTab"
import { CodeScanSastTab } from "../../organisms/CodeScanSastTab"
import { LabelsTab } from "../../organisms/LabelsTab"
import { ScopesTab } from "../../organisms/ScopesTab"
import { UsersTab } from "../../organisms/UsersTab"

import styles from "./SettingsPage.module.scss"

import type { User } from "@/domain/user"
import type { TFunction } from "@/hooks/useTranslation"

type Item = { label: string; admin?: boolean; key?: string }
type LabelProps = { activeKey?: string }

const getClasses = (key: string, activeKey?: string) =>
  cn(styles.title, activeKey === key && styles.active)

const getSectionTitle = (
  item: Item,
  featureFlags: Partial<Features.Flags> & { admin?: boolean } = {}
) => ({
  key: item.key || item.label,
  admin: false,
  label: <Text className={styles.titleInTab}>{item.label}</Text>,
  disabled: true,
  ...featureFlags
})

export const getLabel = (
  item: Item,
  props: LabelProps & Partial<Features.Flags>
) => {
  const { activeKey, ...featureFlags } = props
  const { admin, label, key = label } = item
  const className = getClasses(key, activeKey)

  return {
    key,
    admin,
    label: <Text className={className}>{label}</Text>,
    ...featureFlags
  }
}

export const getItemsTabsByRole = (
  user: User,
  { activeKey, t }: { activeKey: undefined | string; t: TFunction }
) => {
  if (!isKnownUserRole(user)) {
    return []
  }

  const TAB_KEYS = TAB_KEYS_BY_PAGE.SETTINGS
  const defaultItems = getItems(t)

  return [
    { children: null },
    { children: <AuthenticationMethodTab /> },
    { children: <UsersTab /> },
    { children: null },
    { children: <ScopesTab /> },
    { children: <LabelsTab /> },
    { children: <CodeScanSastTab /> },
    { children: null },
    {
      children: (
        <AuthKeysTab
          tabName={TAB_KEYS[4]}
          title={t("apikeys.title")}
          description={t("apikeys.description")}
          useAuthKeys={useAuthKeys}
          useCreateKey={useCreateKey}
          keyType="API_KEYS"
          analyticsEvents={EVENTS.ANALYTIC_EVENTS.SETTINGS.AUTH_KEYS.API_KEYS}
        />
      )
    },
    {
      children: (
        <AuthKeysTab
          tabName={TAB_KEYS[5]}
          title={t("deploymentkeys.title")}
          description={t("deploymentkeys.description")}
          useAuthKeys={useAuthKeys}
          useCreateKey={useCreateKey}
          keyType="DEPLOYMENT_KEYS"
          analyticsEvents={
            EVENTS.ANALYTIC_EVENTS.SETTINGS.AUTH_KEYS.DEPLOYMENT_KEYS
          }
        />
      )
    },
    {
      children: (
        <AuthKeysTab
          tabName={TAB_KEYS[6]}
          title={t("servicekeys.title")}
          description={t("servicekeys.description")}
          useAuthKeys={useAuthKeys}
          useCreateKey={useCreateKey}
          keyType="SERVICE_KEYS"
          analyticsEvents={
            EVENTS.ANALYTIC_EVENTS.SETTINGS.AUTH_KEYS.SERVICE_KEYS
          }
        />
      )
    }
  ]
    .map((item, index) => {
      if (item.children) {
        return { ...getLabel(defaultItems[index], { activeKey }), ...item }
      }

      return getSectionTitle(defaultItems[index])
    })
    .filter(({ admin }) => {
      if (admin) {
        return isUserAdmin(user)
      }

      return true
    })
}
