import { Flex } from "@/atoms"
import { TEASERS } from "@/const/teaser.constants"
import { useTranslation } from "@/hooks/useTranslation"
import { useIssuesBySeverity } from "@/pages/DashboardPage/hooks/useIssuesBySeverity"
import { SeverityIssueBar } from "../../molecules/SeverityIssueBar"
import CardDashboardTemplate from "../../templates/CardDashboardTemplate"

import type { Type } from "@/domain/issue"
import type { IssuesBySeverityWidgetComponent } from "."

export const IssuesBySeverityWidget: IssuesBySeverityWidgetComponent = (
  props
) => {
  const { hook = useIssuesBySeverity, connectSCM } = props
  const { t } = useTranslation()
  const issueTypes: Type[] = [
    "Vulnerable_Package",
    "Vulnerable_Code",
    "Malicious_Package",
    "Exposed_Secret"
  ]
  const [values, isLoading] = hook(issueTypes, props)
  const { response, isRuntime } = values
  const { data = [] } = response || {}
  const loading = isLoading || values.isLoading
  const allIsZero = data.every((values) => values[values.length - 1] === 0)

  return (
    <CardDashboardTemplate
      title={t("Issue Types By Severity")}
      loading={loading}
      teasers={{
        dataReadiness: true,
        scmInstalled: TEASERS.SCM_INSTALLED.codeRepoNotConnected(t),
        kortexInstalled: TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t)
      }}
    >
      <Flex vertical flex={1} gap="8px">
        {issueTypes.map((issueType, index) => {
          const values = data[index]

          return (
            <SeverityIssueBar
              key={`${index}_${values?.[values.length - 1]}`}
              connectSCM={connectSCM}
              issueType={issueType}
              values={values}
              allIsZero={allIsZero}
              runtime={index === 0 && isRuntime}
            />
          )
        })}
      </Flex>
    </CardDashboardTemplate>
  )
}
