import cn from "classnames"

import { Button, Divider, Flex } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import {
  CheckCircleOutlined,
  DeleteOutlined,
  PlayCircleOutlined
} from "@/icons"
import { useWebhookAPI } from "@/pages/IntegrationsPage/hooks/useWebhookAPI"
import { FormWebhook } from "../FormWebhook"

import styles from "./ContentItemWebhook.module.scss"

import { ContentItemWebhookProps } from "."

export const ContentItemWebhook = (props: ContentItemWebhookProps) => {
  const formProps = useWebhookAPI(props)
  const { t } = useTranslation()
  const prefix = "integrations.webhook.configuration.buttons"

  return (
    <Flex vertical>
      <div className={styles.section}>
        <FormWebhook {...formProps} />
      </div>
      <Divider className={styles.divider} />
      <Flex
        justify="space-between"
        className={cn(styles.section, styles.footer)}
      >
        <Flex gap={8}>
          <Button
            danger
            icon={<DeleteOutlined />}
            size="small"
            type="default"
            onClick={formProps.onRemove}
            disabled={formProps.isLoading}
          >
            {t(`${prefix}.remove`)}
          </Button>
          <Button
            icon={<PlayCircleOutlined />}
            size="small"
            type="default"
            disabled={formProps.isLoading}
          >
            {t(`${prefix}.test`)}
          </Button>
        </Flex>
        <Flex gap={8}>
          <Button
            size="small"
            type="text"
            disabled={formProps.isLoading || !formProps.isDirty}
            onClick={formProps.onReset}
          >
            {t("general.reset")}
          </Button>
          <Button
            icon={<CheckCircleOutlined />}
            size="small"
            form={formProps.formId}
            htmlType="submit"
            type="primary"
            disabled={!formProps.isDirty}
            loading={formProps.isLoading}
          >
            {t("general.apply")}
          </Button>
        </Flex>
      </Flex>
      {formProps.modalContext}
    </Flex>
  )
}
