import {
  DeployCommand,
  type CommonDeployCommandProps
} from "@/pages/DeploymentsPage/components/molecules/DeployCommand"
import { DeploymentKeyButton } from "@/pages/DeploymentsPage/components/molecules/DeploymentKeyButton"
import { InlineField } from "@/pages/DeploymentsPage/components/molecules/InlineField"
import { getDefaultValues, getDeployCommand, getLines } from "./helpers"

export const EcsDeployCommand = (
  props: CommonDeployCommandProps & { fargate?: boolean }
) => {
  const lines = getLines()
  const { fargate } = props

  return (
    <DeployCommand
      getDefaultValues={getDefaultValues}
      getDeployCommand={getDeployCommand(fargate)}
      {...props}
    >
      {({ onError, ...props }) => {
        return (
          <>
            <InlineField line={lines["1"]} {...props} />
            <InlineField line={lines["2"]} {...props} />
            <InlineField line={lines["3"]} {...props} />
            <InlineField line={lines["4"]} {...props} />
            <InlineField
              line={lines["5"]}
              buttons={<DeploymentKeyButton onError={onError} />}
              {...props}
            />
            <InlineField line={lines["6"]} {...props} />

            {!!fargate && (
              <>
                <InlineField line={lines["8"]} {...props} />
                <InlineField line={lines["9"]} {...props} />
              </>
            )}
            <InlineField noNullCharacter line={lines["7"]} {...props} />
          </>
        )
      }}
    </DeployCommand>
  )
}
