import {
  useGetInventoryApplication,
  useGetInventoryBranch,
  useGetInventoryCodeRepoPackages,
  useGetInventoryImage,
  useGetInventoryVirtualMachine,
  useGetPackageDescribe,
  useGetPackageVulnerabilities
} from "@/api/useInventory"
import { useIssuesByResourceTriage } from "@/api/useTriage"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useQueryClient } from "@/hooks/useHttp"
import { getCountParams } from "@/organisms/Drawers/hooks/useCounts"
import { getBranchTabData } from "@/pages/DiscoveryPage/application/branch-inventory/useBranchInventory.selector"
import { getIssuesTriageSelector } from "@/pages/TriagePage/application/useTriage.selector"
import { getInitialDataFromQueryClient } from "../common/common"
import {
  getDescribe,
  getImageTabData,
  getPackagesData,
  getPackageVulnerabilities
} from "./useInventory.selectors"

import type { InventoryCodeRepoPackagesParams } from "@/api/useInventory.types"
import type { PackageCommonProps, PropsUseEntity } from "./useInventory.types"

export const getData = (res: { data: object | unknown[] }) =>
  Array.isArray(res.data) ? res.data : [res.data]

export const useEntity = <S, E extends { id: string } = { id: string }>(
  id: string | undefined,
  { resourceRequestURL, hook, select: selectFn }: PropsUseEntity<S>
) => {
  const params = { needle: `/${id}`, pageNumber: "0" }
  const queryClient = useQueryClient()
  const context = hook(params, {
    select: (res: any) => {
      const nextRes = { ...res, data: getData(res) }

      if (typeof selectFn === "function") return selectFn?.(nextRes)

      return nextRes
    },
    enabled: !!id,
    initialData: () =>
      getInitialDataFromQueryClient(
        queryClient.getQueriesData<KosmosApi.Response<E[]> | undefined>({
          queryKey: [resourceRequestURL]
        }),
        (item) => item.id === id
      )
  })

  return context
}

export const useBranchEntity = (id?: string) => {
  const context = useEntity<typeof getBranchTabData>(id, {
    resourceRequestURL: RESOURCES_NAMES.INVENTORIES.BRANCH,
    hook: useGetInventoryBranch,
    select: getBranchTabData
  })
  const repositoryName = context.response?.data[0]?.repositoryName || ""
  const packageManagers: PackageManager[] =
    context.response?.data[0]?.packageManagers || []
  const estimatedPackageManagers: PackageManager[] =
    context.response?.data[0]?.estimatedPackageManagers || []

  const isPackageManagerEstimated =
    packageManagers?.filter((manager) =>
      estimatedPackageManagers.includes(manager)
    ).length > 0

  return [context, { repositoryName, isPackageManagerEstimated }] as const
}

export const useImageEntity = (id: string | undefined) => {
  const context = useEntity<typeof getImageTabData>(id, {
    resourceRequestURL: RESOURCES_NAMES.INVENTORIES.IMAGES,
    hook: useGetInventoryImage,
    select: getImageTabData
  })
  const imageId = context.response?.data[0]?.imageId

  return [context, imageId] as const
}

export const useApplicationEntity = (id: string | undefined) => {
  const context = useEntity(id, {
    resourceRequestURL: RESOURCES_NAMES.INVENTORIES.APPLICATION,
    hook: useGetInventoryApplication
  })
  const resourceId = context.response?.data[0]?.data?.resourceIds?.[0]

  return [context, resourceId] as const
}

export const usePackagesCodeRepository = (
  id: string | undefined,
  params: InventoryCodeRepoPackagesParams
) => {
  const [entityContext, { repositoryName }] = useBranchEntity(id)
  const config = { enabled: !!repositoryName }
  const context = useGetInventoryCodeRepoPackages(repositoryName, params, {
    select: getPackagesData,
    ...config
  })
  const countsContext = useGetInventoryCodeRepoPackages(
    repositoryName,
    getCountParams(),
    config
  )

  return [entityContext.isLoading ? entityContext : context, countsContext]
}

export const useGetIssuesForResource = (
  resourceIdName: string | undefined,
  filterParams: Record<string, unknown>
) => {
  const { packageId, projectId } = filterParams
  const isPackage =
    packageId && projectId && resourceIdName === `${projectId}-${packageId}`
  const componentParams = isPackage
    ? filterParams
    : { resourceId: resourceIdName, ...filterParams }
  const context = useIssuesByResourceTriage(componentParams, {
    enabled: !!resourceIdName,
    select: getIssuesTriageSelector
  })
  const commonCountParams = {
    issueType: filterParams.issueType as string,
    ...getCountParams()
  }
  const countParams = isPackage
    ? { packageId, projectId, ...commonCountParams }
    : { resourceId: resourceIdName, ...commonCountParams }

  const countsContext = useIssuesByResourceTriage(countParams, {
    enabled: !!resourceIdName,
    select: getIssuesTriageSelector
  })
  return [context, countsContext]
}

export const usePackageVulnerabilities = (props: PackageCommonProps) => {
  const context = useGetPackageVulnerabilities(props, {
    enabled: !!props.projectId && !!props.id,
    select: getPackageVulnerabilities
  })

  return context
}

export const usePackageDescribe = (props: PackageCommonProps) => {
  const context = useGetPackageDescribe(props, {
    enabled: !!props.projectId && !!props.id,
    select: getDescribe
  })

  return context
}

export const useVirtualMachineEntity = (id: string | undefined) => {
  const context = useGetInventoryVirtualMachine(
    { needle: `/${id}` },
    { enabled: !!id }
  )
  const entity = Array.isArray(context.response?.data)
    ? context.response.data[0]
    : context.response?.data
  const resourceId = entity?.id

  return [context, resourceId] as const
}
