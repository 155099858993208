import { Filter as FilterCompComponent } from "@/organisms/FilterNew/Filter"
import { SmartSearch } from "@/organisms/SmartSearch"

export const Filter = () => {
  const keys = [{ key: "query", label: "query", type: "query" }]

  return (
    <FilterCompComponent.Provider keys={keys}>
      <FilterCompComponent.Template
        search={<SmartSearch onlySearch filters={keys} />}
      />
    </FilterCompComponent.Provider>
  )
}
