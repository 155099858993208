import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import RelativeTime from "dayjs/plugin/relativeTime"

import type { UnitType } from "dayjs"
import type {
  DateArgument,
  DateDisplayFormat,
  Timestamp
} from "./date.helpers.types"

export const DEFAULT_FORMAT = "ll"
export const DEFAULT_LONG_FORMAT = "lll"

export const setPluginsDateHelpers = () => {
  dayjs.extend(RelativeTime)
  dayjs.extend(LocalizedFormat)
}

export const getDisplayValue = (
  date: DateArgument | "Invalid Date",
  fn: (data: Date) => string
) => {
  try {
    if (!date || date === "Invalid Date") return ""

    const result = date.toString()

    if (result === "Invalid Date") return ""

    return fn(date)
  } catch (err) {
    return ""
  }
}

export const getTimestampInMillisecondsFromSecond = (
  timestampInSeconds: Timestamp
) => timestampInSeconds * 1000

export const getDateFromSeconds = (timestampInSeconds: Timestamp) => {
  if (timestampInSeconds < 86400) return null

  return new Date(getTimestampInMillisecondsFromSecond(timestampInSeconds))
}

export const getRelativeTimeFromNow = (date: DateArgument) => {
  return getDisplayValue(date, (d) => dayjs(d).fromNow())
}

export const getDateInUserLocale = (
  date: DateArgument,
  format: DateDisplayFormat
) => {
  return getDisplayValue(date, (d) => dayjs(d).format(format))
}

export const getDiffToNow = (date: DateArgument, unit: UnitType = "days") =>
  dayjs().diff(date, unit)
