import { Breadcrumb, Link } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { Heading } from "@/molecules/Heading"
import { HeaderPage } from "@/organisms/HeaderPage"
import { useWebhookAPI } from "@/pages/IntegrationsPage/hooks/useWebhookAPI"
import { PageWithBreadcrumbTemplate } from "@/templates/PageWithBreadcrumbTemplate"
import { FormWebhook } from "../../organisms/WebhookConfigurationTab/components/FormWebhook"
import { ConnectIntegrationFooter } from "../ConnectIntegrationPage/ConnectIntegrationFooter"

import styles from "./WebhookIntegrationPage.module.scss"

import { type MessageInstance } from "@/atoms"

export const WebhookIntegrationPage = (props: {
  messageApi: MessageInstance
}) => {
  const { t } = useTranslation()
  const formProps = useWebhookAPI({ messageApi: props.messageApi })
  const title = t("integrations.webhook.add")

  return (
    <PageWithBreadcrumbTemplate
      full
      header={
        <HeaderPage
          title={
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link to="/integrations">
                      {t("navigation.integrations")}
                    </Link>
                  )
                },
                {
                  title: (
                    <Link to={formProps.getBackToIntegrationUrl()}>
                      Webhook
                    </Link>
                  )
                },
                { title }
              ]}
            />
          }
        />
      }
      footer={
        <ConnectIntegrationFooter
          isEnabled={!formProps.isDirty}
          isLoading={formProps.isLoading}
          okText="Add Webhook"
          okProps={{ form: "webhook-form", htmlType: "submit" }}
          onCancel={() => formProps.backToIntegration()}
        />
      }
    >
      <div className={styles.title}>
        <Heading level={4} title={title} />
        <Heading level={6} title={t("integrations.webhook.form.title")} />
      </div>
      <FormWebhook {...formProps} />
      {formProps.modalContext}
    </PageWithBreadcrumbTemplate>
  )
}
