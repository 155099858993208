import { WIDTH_DEFAULT } from "@/organisms/Drawers/const"
import { SbomTab } from "../SbomTab"
import { TabsDrawerTemplate } from "../TabsDrawerTemplate"
import { IssuesTabImageDrawer } from "./components/IssuesTabImageDrawer"
import { TitleImageDrawer } from "./components/TitleImageDrawer"

import type { ImageDrawerComponent } from "."

export const ImageDrawer: ImageDrawerComponent = ({
  widthAdditive,
  ...props
}) => {
  const tabs = ["sbom", "scaIssues"]
  const components = [SbomTab, IssuesTabImageDrawer]

  return (
    <TabsDrawerTemplate
      name="ImageDrawer"
      tabs={tabs}
      components={components}
      Title={TitleImageDrawer}
      width={WIDTH_DEFAULT + widthAdditive}
      {...props}
    />
  )
}
