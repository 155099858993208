import { Popover } from "@/atoms"
import { SingleImageResourcePopover } from "@/molecules/Resources"

import type { ImageNameCellComponent } from "."

export const ImageNameCell: ImageNameCellComponent = (value, record) => {
  const {
    key: id,
    displayName: name,
    digest = "",
    registry,
    ...rest
  } = record || {}
  const prefix = registry ? `${registry}/` : ""
  const props = { id, name: prefix + name, digest, ...rest }

  return (
    <Popover
      trigger="hover"
      placement="topLeft"
      content={<SingleImageResourcePopover {...props} />}
    >
      {value}
    </Popover>
  )
}
