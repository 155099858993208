import { Text } from "@/atoms"
import {
  isFromPackageEvidence,
  isPackageLoadedNoRuntime,
  isPackageNotRuntimeAndJvm,
  isPackageNotRuntimeAndNotJvm,
  isPackageRuntimeAndJvm,
  isPackageRuntimeAndNotJvm
} from "@/domain/issue"
import { useTranslation } from "@/hooks/useTranslation"

import styles from "../RuntimeEvidence.module.scss"

import type { RuntimeEvidenceProps } from ".."

export const PackageRuntimeEvidence = (props: RuntimeEvidenceProps) => {
  const { record } = props
  const isPackageLevel = isFromPackageEvidence(record)

  const { t: tRuntimeEvidence } = useTranslation("translation", {
    keyPrefix: "runtimeEvidence"
  })

  if (!isPackageLevel) return null

  const packageRuntimeAndJVM = isPackageRuntimeAndJvm(record)
  const packageRuntimeAndNotJVM = isPackageRuntimeAndNotJvm(record)
  const packageLoadedNoRuntime = isPackageLoadedNoRuntime(record)
  const packageNotRuntimeAndJVM = isPackageNotRuntimeAndJvm(record)
  const packageNotRuntimeAndNotJVM = isPackageNotRuntimeAndNotJvm(record)

  return (
    <Text className={styles.content}>
      {packageLoadedNoRuntime && tRuntimeEvidence("package_loaded_no_runtime")}

      {packageNotRuntimeAndJVM && tRuntimeEvidence("package_not_in_runtime")}

      {packageNotRuntimeAndNotJVM && tRuntimeEvidence("package_not_loaded")}

      {packageRuntimeAndJVM && tRuntimeEvidence("package_in_runtime")}

      {packageRuntimeAndNotJVM && tRuntimeEvidence("package_loaded")}
    </Text>
  )
}
