import { useSearchParams } from "react-router-dom"

import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useParallelQueries } from "@/hooks/useHttp"
import { useServices } from "@/hooks/useServices"
import { useTranslation } from "@/hooks/useTranslation"
import { useFeatureFlags } from "@/hooks/useUser"
import { getCards } from "./helpers"

import type { GetCardProps } from "."

export const useFunnelTriage = (props?: GetCardProps) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const kortexInstalled = useFeatureFlags("kortexInstalled")

  const { auth } = useServices()
  const isDemo = auth.isDemo()
  const enabled = isDemo

  const cards = getCards(t, searchParams, {
    runtime: !!kortexInstalled,
    isDemo,
    ...props
  })

  const context = useParallelQueries<{ data: number }>(
    cards.map(({ params }) => ({
      url: RESOURCES_NAMES.TRIAGE.ISSUES_COUNT,
      params
    }))
  )

  const contextFixable = useParallelQueries<{ data: number }>(
    cards.map(({ params }) => ({
      enabled,
      url: RESOURCES_NAMES.TRIAGE.ISSUES_COUNT,
      params: { fixability: "full,partial,has_fix", ...params }
    }))
  )

  return [context, cards, kortexInstalled, contextFixable, isDemo] as const
}
