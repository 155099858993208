import { Button, Result } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"

import { FrownOutlined, MehOutlined } from "@/icons"
import styles from "./LayoutErrorResult.module.scss"
import { LayoutErrorResultProps } from "./LayoutErrorResult.types"

export const LayoutErrorResult = (props: LayoutErrorResultProps) => {
  const { errorType } = props
  const { t: tResult } = useTranslation("translation", {
    keyPrefix: "layoutError"
  })
  const { t } = useTranslation()
  const icon =
    errorType === "Forbidden" ? (
      <FrownOutlined className={styles.icon} />
    ) : (
      <MehOutlined className={styles.icon} />
    )
  return (
    <Result
      className={styles.container}
      icon={icon}
      title={
        <div className={styles.title}>{tResult(errorType.toLowerCase())}</div>
      }
      subTitle={
        <Button
          type="link"
          onClick={() => {
            window.location.href = "/"
          }}
        >
          {`${tResult("reload")} ${t("navigation.dashboard")}`}
        </Button>
      }
    />
  )
}
