import { isCodeRepoResource } from "@/domain/resource"
import { preventDefaultWrapper } from "@/helpers/utils"
import { CODE_REPOSITORY_DRAWER_ID } from "@/organisms/Drawers/const"
import { Resource } from "."

import styles from "./Resources.module.scss"

import type { ActionOpenDrawer } from "@/hooks/useLinkDrawerTooltip"
import type { ResourcesProps } from "."

export const ContentResourcesPopover = (
  props: Pick<ResourcesProps, "resources"> & { openDrawer: ActionOpenDrawer }
) => (
  <div className={styles.containerTooltip} onClick={preventDefaultWrapper()}>
    {props.resources.map((resource, index) => {
      const isCodeRepo = isCodeRepoResource(resource)
      const shouldOpenDrawer = isCodeRepo && props.openDrawer
      const onClick = shouldOpenDrawer
        ? preventDefaultWrapper(() =>
            props.openDrawer(CODE_REPOSITORY_DRAWER_ID, resource.id)
          )
        : undefined

      return <Resource key={index} onClick={onClick} {...resource} />
    })}
  </div>
)
