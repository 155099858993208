import cn from "classnames"
import { Fragment } from "react"

import { Link, Statistic, Text } from "@/atoms"
import { useNumberConvertor } from "@/hooks/useNumberConvertor"
import { useTranslation } from "@/hooks/useTranslation"

import ExecutedSourceImg from "@/assets/images/executed_runtime_function_level.png"
import NoExecutedSourceImg from "@/assets/images/no_executed_runtime_function_level.png"

import styles from "./VulnerableFunctionSlide.module.scss"

import type { VulnerableFunctionSlideComponent } from "."

export const VulnerableFunctionSlide: VulnerableFunctionSlideComponent = (
  props
) => {
  const { executed, value, suffix, to } = props
  const source = executed ? ExecutedSourceImg : NoExecutedSourceImg
  const { t } = useTranslation()
  const { abbreviateNumber } = useNumberConvertor()
  const colorCN = cn(styles.textExecuted, executed && styles.executed)
  const Wrapper = to ? Link : Fragment
  const propsWrapper = to ? { to } : {}

  return (
    <div className={styles.container}>
      <Wrapper {...propsWrapper}>
        <Statistic
          prefix={<img height={40} src={source} alt="Executed Source" />}
          suffix={suffix && <Text type="secondary">{`${suffix}%`}</Text>}
          valueRender={() => (
            <span className={cn(colorCN, styles.value)}>
              {abbreviateNumber(value)}
            </span>
          )}
        />
      </Wrapper>
      <div>
        <Text className={styles.text} type="secondary">
          {t(
            "Evidence from Kodem's memory analysis confirms that vulnerable functions in these "
          )}
        </Text>
        <span
          className={cn(styles.text, colorCN)}
        >{`${t(executed ? "issues are executed" : "issues are not executed")}.`}</span>
      </div>
    </div>
  )
}
