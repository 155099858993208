const INVENTORY_RESOURCE = "/v1/inventory"
const INTEGRATIONS = "/v1/integrations"
const SBOM = "/v1/sbom"
const SETTINGS = "/v1/settings"
const TICKETING = "/v1/ticketing"
const ISSUES = "/v1/issues"
const TRIAGE = "/v1/triage"
const AUTH = "/v1/auth"
const TENANTS = "/v1/tenants"
const ONBOARDING = "/v1/onboarding"
const LABELS = "/v1/labels"
const EXPORT = "/export"

export const RESOURCES_NAMES = {
  AUTHENTICATION: {
    AUTH: AUTH,
    TENANT: `${AUTH}/tenant`,
    API_KEYS: `${AUTH}/api-keys`,
    DEPLOYMENT_KEYS: `${AUTH}/deployment-keys`,
    SERVICE_KEYS: `${AUTH}/service-keys`,
    ACCEPT_INVITE: `${AUTH}/accept-invite`,
    FORGOT_PASSWORD: `${AUTH}/forgot-password`,
    LOGOUT: `${AUTH}/logout`,
    RESET_PASSWORD: `${AUTH}/reset-password`,
    DOCS: `${AUTH}/docs`
  },
  POSTURE: "/v1/posture",
  INVENTORY: INVENTORY_RESOURCE,
  TENANTS: {
    GCP_TOKEN_VALIDATION: `${TENANTS}/gcp-marketplace/validate`,
    SIGNUP: `${TENANTS}`
  },
  SETTINGS: {
    USERS: `${SETTINGS}/users`,
    SCOPES: `${SETTINGS}/scopes`,
    AUTH_METHODS: `${SETTINGS}/auth_methods`,
    POLICIES: {
      LIST: `${SETTINGS}/policies`,
      CONDITIONS: {
        OPTIONS: `${SETTINGS}/policies/conditions/options`
      },
      SCOPE: {
        OPTIONS: `${SETTINGS}/policies/scope/options`
      }
    },
    CI_POLICIES: {
      LIST: `${SETTINGS}/ci-policies`,
      CONDITIONS: {
        OPTIONS: `${SETTINGS}/ci-policies/conditions/options`
      }
    },
    SERVICE_KEYS: `${SETTINGS}/service-keys`,
    SAST: `${SETTINGS}/sast`
  },
  INVENTORIES: {
    DIRECT_DEPENDENCY: `${INVENTORY_RESOURCE}/direct_dependency`,
    CONTAINER_BASE_IMAGE: `${INVENTORY_RESOURCE}/container_base_image`,
    CONTAINER_BASE_IMAGE_EXPORT: `${INVENTORY_RESOURCE}/container_base_image${EXPORT}`,
    BRANCH: `${INVENTORY_RESOURCE}/branch`,
    BRANCH_EXPORT: `${INVENTORY_RESOURCE}/branch${EXPORT}`,
    ARCHIVED: `${INVENTORY_RESOURCE}/branch/archived`,
    IMAGES: `${INVENTORY_RESOURCE}/container_image`,
    IMAGES_EXPORT: `${INVENTORY_RESOURCE}/container_image${EXPORT}`,
    COUNTS: `${INVENTORY_RESOURCE}/counts`,
    APPLICATION: `${INVENTORY_RESOURCE}/application`,
    APPLICATION_EXPORT: `${INVENTORY_RESOURCE}/application${EXPORT}`,
    ENVIRONMENT: `${INVENTORY_RESOURCE}/environment`,
    SNAPSHOT: `${INVENTORY_RESOURCE}/snapshot`,
    SAST_ISSUES: `${INVENTORY_RESOURCE}/branch/sast_issues`,
    SAST_ISSUES_COUNT: `${INVENTORY_RESOURCE}/branch/sast_issues/count`,
    PACKAGE: `${INVENTORY_RESOURCE}/packages`,
    PACKAGE_EXPORT: `${INVENTORY_RESOURCE}/packages${EXPORT}`,
    PACKAGE_REFERENCES_COUNT: `${INVENTORY_RESOURCE}/packages/projects/count`,
    VM: `${INVENTORY_RESOURCE}/vm`
  },
  SBOM: {
    EXPORT: `${SBOM}${EXPORT}`
  },
  INTEGRATIONS: {
    LIST: INTEGRATIONS,
    CALLBACK: `${INTEGRATIONS}/callback`,
    NOTIFY_CALLBACK: `${INTEGRATIONS}/callback/notify-admin`,
    ECR: { PRE_INSTALL: `${INTEGRATIONS}/pre-install/ecr` }
  },
  ALERTS: "/v1/alerts",
  REMEDIES: "/v1/remedies",
  DEPLOYMENTS: "/v1/deployments",
  TICKETING: {
    JIRA: `${TICKETING}/jira`
  },
  ISSUES: {
    REPOSITORIES: `${ISSUES}/repositories`,
    IMAGES: `${ISSUES}/images`
  },
  TRIAGE: {
    PROJECTS: `${TRIAGE}/projects`,
    FILTERS: `${TRIAGE}/filters`,
    ISSUES: `${TRIAGE}/issues`,
    ISSUES_COUNT: `${TRIAGE}/issues/count`,
    EXPORT: `${TRIAGE}/issues${EXPORT}`
  },
  ONBOARDING: {
    ONBOARDING_STATUS: `${ONBOARDING}/status`,
    DISMISS_ONBOARDING: `${ONBOARDING}/dismiss-onboarding`,
    WELCOME_STATUS: `${ONBOARDING}/welcome-status`,
    DISMISS_WELCOME: `${ONBOARDING}/dismiss-welcome`
  },
  LABELS: {
    LIST: `${LABELS}`,
    COLORS: `${LABELS}/colors`
  },
  STATISTICS: "/v1/statistics"
}
