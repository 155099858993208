import { NAMES_ROUTES } from "@/const/routes.constants"
import { Navigate, Route, Routes } from "react-router-dom"
import { DeploymentsPage } from "./components/pages/DeploymentsPage"
import { NewDeploymentPage } from "./components/pages/NewDeploymentPage"

export const DeploymentRouts = () => {
  const { NEW_DEPLOYMENT } = NAMES_ROUTES.DEPLOYMENTS

  return (
    <Routes>
      <Route path="/" element={<DeploymentsPage />} />
      <Route
        path={`/${NEW_DEPLOYMENT}/:type?`}
        element={<NewDeploymentPage />}
      />
      <Route path="/*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}
