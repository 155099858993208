import { Text } from "@/atoms"
import {
  getClasses,
  getDisplayClassesTranslationKey,
  getDisplayFunctionsTranslationKey,
  getFunctionCount,
  getFunctionName,
  getFunctions,
  isFromClassEvidence,
  isFromFunctionEvidence
} from "@/domain/issue"
import { useTranslation } from "@/hooks/useTranslation"
import { List } from "@/pages/DiscoveryPage/components/molecules/List"

import styles from "../RuntimeEvidence.module.scss"

import type { RuntimeEvidenceProps } from ".."

export const FunctionLevel = (props: RuntimeEvidenceProps) => {
  const { t: tRuntimeEvidence } = useTranslation("translation", {
    keyPrefix: "runtimeEvidence"
  })
  const { record } = props

  const isFunctionLevel = isFromFunctionEvidence(record)
  const isClassLevel = isFromClassEvidence(record)
  if (!isFunctionLevel && !isClassLevel) return null

  const displayFunctionKey = getDisplayFunctionsTranslationKey(record)
  const countFunctions = getFunctionCount(record)
  const displayClassesKey = getDisplayClassesTranslationKey(record)

  const noFunctions = countFunctions === 0
  const displayFunction = !noFunctions && isFunctionLevel
  const displayOneFunction = displayFunction && countFunctions === 1
  const displayFunctionsList = displayFunction && countFunctions > 1

  const classes = getClasses(record)
  const countClasses = classes?.length || 0
  const noClasses = countClasses === 0
  const displayClasses = !noClasses && isClassLevel
  const displayOneClass = displayClasses && countClasses === 1
  const displayClassesList = displayClasses && countClasses > 1

  return (
    <Text className={styles.content}>
      <Text>
        {isFunctionLevel
          ? tRuntimeEvidence(displayFunctionKey, {
              count: countFunctions
            })
          : tRuntimeEvidence(displayClassesKey, {
              count: countClasses
            })}
      </Text>
      <br />
      {(displayOneFunction || displayOneClass) && (
        <Text code className={styles.secondaryTextContent}>
          {isFunctionLevel
            ? getFunctionName(getFunctions(record)[0])
            : getClasses(record)[0]}
        </Text>
      )}
      {(displayFunctionsList || displayClassesList) && (
        <List
          limit={3}
          items={
            displayFunctionsList ? getFunctions(record) : getClasses(record)
          }
          classes={{
            list: styles.functionsOrClassesListEntity,
            button: styles.functionsOrClassesListButton
          }}
          render={(name, i) => (
            <Text code className={styles.secondaryTextContent}>
              {`${i + 1}. ${displayFunctionsList ? getFunctionName(name) : name}`}
            </Text>
          )}
        />
      )}
    </Text>
  )
}
