import dayjs from "dayjs"

import type { Statistic, StatisticsResponse } from "@/api/useStatistics.types"

export const getTimestampPosture = (data: Statistic) => data.timestamp * 1000

export const statisticsSelector = (response: StatisticsResponse) => {
  const data = response?.data || []
  const day = dayjs()

  const { value = 0 } = data[0] || {}
  const weekAgoIndex = data
    .slice(0, 8)
    .findIndex(
      (posture) => day.diff(getTimestampPosture(posture), "days") === 7
    )

  return {
    weekAgoIndex,
    data,
    score: value || 0
  }
}
