import { getOsFilter } from "@/helpers/filters.helper"
import type { TFunction } from "@/hooks/useTranslation"
import { Filters } from "@/organisms/SmartSearch/types/options.types"
import {
  getCVEFilter,
  getRuntimeFilter,
  getSeverityFilter,
  InventoryFilters
} from "@/pages/DiscoveryPage/utils/filters.utils"

export const getDefaultFilters = (t: TFunction): Filters => [
  InventoryFilters.application(t),
  InventoryFilters.repositoryName(t),
  InventoryFilters.imageName(t),
  InventoryFilters.environment(t),
  InventoryFilters.namespace(t),
  getRuntimeFilter(),
  getSeverityFilter(),
  getCVEFilter(),
  {
    ...getOsFilter(t),
    // We override key here to match the key from BE
    key: "operatingSystem"
  },
  {
    key: "ingress",
    order: 3,
    label: t("filters.ingress"),
    type: "single",
    options: [
      { label: "Yes", value: "YES", disabled: false },
      { label: "No", value: "NO", disabled: false }
    ]
  },
  {
    key: "publicImage",
    order: 2,
    label: t("filters.publicImage"),
    type: "single",
    options: [
      { label: "Yes", value: "YES", disabled: false },
      { label: "No", value: "NO", disabled: false }
    ]
  }
]

export const getFilters = (
  t: TFunction,
  props?: { internetFacingFF?: boolean }
) => {
  if (props?.internetFacingFF) {
    const filters = getDefaultFilters(t).map((filter) => {
      if (filter.options) {
        filter.options.push({ label: "N/A", value: "", disabled: true })
      }
      return filter
    })

    return [
      {
        key: "internetFacing",
        label: t("filters.internetFacing"),
        type: "single",
        order: 1,
        options: [
          { label: "Yes", value: "YES", disabled: false },
          { label: "No", value: "NO", disabled: false },
          {
            displayLabel: "N/A",
            label: "Undetermined",
            value: "UNDETERMINED",
            disabled: false
          }
        ]
      },
      ...filters
    ]
  }

  return getDefaultFilters(t)
}
