import { Collapse, Paragraph, Text } from "@/atoms"

import { useTranslation } from "@/hooks/useTranslation"
import { ContentItemWebhook } from "./components/ContentItemWebhook"
import { LabelItemWebhook } from "./components/LabelItemWebhook"

import styles from "./WebhookConfigurationTab.module.scss"

import type { WebhookConfigurationTabProps } from "."

export const WebhookConfigurationTab = (
  props: WebhookConfigurationTabProps
) => {
  const installations = props.configuration?.installations || []
  const noWebhooks = !installations.length
  const { t } = useTranslation("translation", {
    keyPrefix: "integrations.webhook.configuration"
  })

  return (
    <div>
      <Paragraph className={styles.title}>{t("title")}</Paragraph>
      {noWebhooks && <Text>{t("noData")}</Text>}
      {!noWebhooks && (
        <Collapse
          className={styles.collapse}
          items={installations.map((values) => ({
            key: `${values.id}`,
            label: <LabelItemWebhook values={values} />,
            children: <ContentItemWebhook initialValues={values} {...props} />
          }))}
        />
      )}
    </div>
  )
}
