import {
  getSeverityFilter,
  InventoryFilters
} from "@/pages/DiscoveryPage/utils/filters.utils"

import { getOsFilter } from "@/helpers/filters.helper"
import type { TFunction } from "@/hooks/useTranslation"

export const getFilters = (t: TFunction) => {
  const keyValues = "filters.helpers.multipleValues"

  return [
    getSeverityFilter(),
    InventoryFilters.environment(t),
    getOsFilter(t),
    {
      key: "instanceType",
      label: t("filters.instanceType"),
      type: "input",
      helper: [
        {
          label: t(keyValues, { label: t("filters.instanceType") }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "instance types"
          })
        }
      ]
    },
    {
      key: "autoScalingGroups",
      label: "auto-scaling-groups",
      type: "input",
      helper: [
        {
          label: t(keyValues, { label: t("filters.autoScalingGroups") }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "auto scaling groups"
          })
        }
      ]
    },
    {
      key: "imageId",
      label: t("filters.imageId"),
      type: "input",
      helper: [
        {
          label: t(keyValues, { label: t("filters.imageId") }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "image ids"
          })
        }
      ]
    },
    {
      key: "instanceId",
      label: t("filters.instanceId"),
      type: "input",
      helper: [
        {
          label: t(keyValues, { label: t("filters.instanceId") }),
          description: t("inventory.generalFilters.multipleSubstring", {
            name: "instance ids"
          })
        }
      ]
    }
  ]
}
