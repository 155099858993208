import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Skeleton, Tabs, type TabsProps } from "@/atoms"
import { NAMES_ROUTES } from "@/const/routes.constants"
import { AKS, EC2, ECS, EKS, GKE, Kubernetes } from "@/icons"
import { TYPE_DEPLOY } from "../../../const"
import { DeployTypeButton } from "../../molecules/DeployTypeButton"
import { CommonDeployTab } from "../../organisms/CommonDeployTab"
import { EC2Tab } from "../../organisms/EC2Tab"
import { EcsTab } from "../../organisms/EcsTab"

import styles from "./DeployTabs.module.scss"

import type { DeployTabsProps } from "."

export const DeployTabs = ({ loading, tabProps }: DeployTabsProps) => {
  const navigate = useNavigate()
  const { type } = useParams()
  const defaultTab = TYPE_DEPLOY.KUBERNETES

  const items = [
    {
      label: <DeployTypeButton name="Kubernetes" Icon={Kubernetes} />,
      key: defaultTab,
      children: <CommonDeployTab {...tabProps} />
    },
    {
      label: <DeployTypeButton name="GKE" Icon={GKE} />,
      key: TYPE_DEPLOY.GKE,
      children: <CommonDeployTab {...tabProps} />
    },
    {
      label: <DeployTypeButton name="AKS" Icon={AKS} />,
      key: TYPE_DEPLOY.AKS,
      children: <CommonDeployTab {...tabProps} />
    },
    {
      label: <DeployTypeButton name="EKS" Icon={EKS} />,
      key: TYPE_DEPLOY.EKS,
      children: <CommonDeployTab {...tabProps} />
    },
    // {
    //   label: <DeployTypeButton name="EKS Fargate" Icon={Fargate} />,
    //   key: TYPE_DEPLOY.EKS_FARGATE,
    //   children: <EksTab fargate {...tabProps} />
    // },
    {
      label: <DeployTypeButton name="EC2" Icon={EC2} />,
      key: TYPE_DEPLOY.EC2,
      children: <EC2Tab {...tabProps} />
    },
    {
      label: <DeployTypeButton name="ECS" Icon={ECS} />,
      key: TYPE_DEPLOY.ECS,
      children: <EcsTab {...tabProps} />
    },
    {
      label: <DeployTypeButton name="ECS Fargate" Icon={ECS} />,
      key: TYPE_DEPLOY.ECS_FARGATE,
      children: <EcsTab fargate {...tabProps} />
    }
  ] as TabsProps["items"]

  useEffect(() => {
    const typeExist =
      !!type &&
      !!TYPE_DEPLOY[
        type.replace("-", "_").toUpperCase() as keyof typeof TYPE_DEPLOY
      ]
    const isEnabled = !items?.find((item) => item.key === type)?.disabled
    const shouldRedirectToDefaultTab = !typeExist || !isEnabled

    const { ROOT, NEW_DEPLOYMENT } = NAMES_ROUTES.DEPLOYMENTS

    const url = shouldRedirectToDefaultTab
      ? `/${ROOT}/${NEW_DEPLOYMENT}/${defaultTab}`
      : "."

    navigate(url, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Tabs
      className={styles.container}
      activeKey={type}
      items={
        loading
          ? [
              {
                label: (
                  <Skeleton.Node active className={styles.nodeSkeleton}>
                    <div />
                  </Skeleton.Node>
                ),
                key: "loading",
                children: (
                  <>
                    <Skeleton active paragraph={{ rows: 3 }} />
                    <Skeleton active paragraph={{ rows: 3 }} />
                  </>
                )
              }
            ]
          : items
      }
      onChange={(key) => {
        const { ROOT, NEW_DEPLOYMENT } = NAMES_ROUTES.DEPLOYMENTS

        navigate(`/${ROOT}/${NEW_DEPLOYMENT}/${key}`)
      }}
    />
  )
}
