import {
  DEFAULT_DOWNLOAD_URL,
  REGCRED_YAML_FILENAME,
  REGISTRY_CREDENTIALS_DOWNLOAD_URL
} from "@/pages/DeploymentsPage/const"

export const getKortexFilename = (version: string) => `kortex-${version}.tgz`

export const getCommonFiles = (version: string) => [
  { name: getKortexFilename(version), url: DEFAULT_DOWNLOAD_URL },
  { name: REGCRED_YAML_FILENAME, url: REGISTRY_CREDENTIALS_DOWNLOAD_URL }
]
