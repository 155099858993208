export const LANGUAGES: Language[] = [
  "apk",
  "binary",
  "c",
  "c#",
  "c++",
  "dart",
  "deb",
  "dotnet",
  "go",
  "java",
  "javascript",
  "kotlin",
  "lua",
  "php",
  "python",
  "R",
  "rpm",
  "ruby",
  "rust",
  "scala",
  "swift",
  "typescript"
] as const
