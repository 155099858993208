import { Resource } from "."

import type { SingleImageResourcePopoverProps } from "."

export const SingleImageResourcePopover: React.FC<
  SingleImageResourcePopoverProps
> = (props) => {
  const { onClick } = props

  let data

  if ("resources" in props) data = props.resources
  else {
    const { digest, tag, name, type: _, ...rest } = props
    const type = "ImageRepository" as const

    data = [{ type, name: `${name}:${tag}`, tag: digest, ...rest }]
  }

  return (
    <div>
      {data.map(({ tag, ...resource }, index) => (
        <Resource
          key={index}
          copyable={{
            tag: { text: tag, tooltips: false },
            name: { text: resource.name, tooltips: false }
          }}
          tag={`Digest: ${tag}`}
          onClick={onClick}
          {...resource}
        />
      ))}
    </div>
  )
}
