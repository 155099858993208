import { useTranslation } from "@/hooks/useTranslation"
import {
  DeployTab,
  TitleStepDeployTab,
  type CommonDeployTabProps
} from "@/pages/DeploymentsPage/components/molecules/DeployTab"
import { CommonDeployCommand } from "@/pages/DeploymentsPage/components/organisms/CommonDeployCommand"
import { DownloadStep } from "@/pages/DeploymentsPage/components/organisms/DownloadStep"
import { getCommonFiles } from "@/pages/DeploymentsPage/helpers"

export const CommonDeployTab = (props: CommonDeployTabProps) => {
  const { noError, version } = props
  const files = getCommonFiles(version)
  const { t } = useTranslation()

  return (
    <DeployTab
      steps={[
        {
          title: (
            <DownloadStep
              noError={noError}
              files={files}
              title={t("deployments.kubernetes.deploymentStep")}
            />
          ),
          status: "process"
        },
        {
          title: (
            <TitleStepDeployTab prefix={t("general.deploy")}>
              {`- ${t("deployments.kubernetes.commandStep")}`}
            </TitleStepDeployTab>
          ),
          description: <CommonDeployCommand {...props} />,
          status: "process"
        }
      ]}
      message={
        <span>
          {t("deployments.kubernetes.alert.text")}
          <br />
          <b>{t("deployments.kubernetes.alert.note")}</b>
        </span>
      }
    />
  )
}
