import { message as messageToast } from "@/atoms"
import { ERROR_EVENTS } from "@/const/event.constants"
import { isAuthorizationError } from "@/helpers/error.helper"
import useServices from "@/hooks/useServices"
import {
  Query,
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient
} from "@tanstack/react-query"
import { useEffect } from "react"

import type { Services } from "@/interfaces/services"
import type {
  ConfigLoadMore,
  GetUseMutationOptions,
  KeysPredicate,
  QueryFunctionContext,
  QueryKeyT,
  QueryOptions,
  Updater,
  UseQueryOptions
} from "./useHttp.types"

export { useMutation, useQueryClient } from "@tanstack/react-query"
export type {
  Config,
  ConfigInfinityQuery,
  GetUseMutationOptions,
  Params,
  QueryClient,
  Updater
} from "./useHttp.types"

const STALE_TIME = 1000 * 60 * 5 // 5 minutes
const CACHE_TIME = 1000 * 60 * 10 // 10 minutes

export const fetcher = <T>(
  {
    queryKey,
    headers
  }: QueryFunctionContext & { headers?: Record<string, string> },
  services: { http: Services.IHttpAdapter }
): Promise<T> => {
  const { http } = services
  const [url, params] = queryKey

  return http.get<T>(url, { params: { ...params }, headers })
}

export const useHttp = <TQueryData, TData = TQueryData>(
  url: string | null,
  params?: object,
  config?: Omit<QueryOptions<TQueryData, Error, TData>, "queryKey"> & {
    headers?: Record<string, string>
  }
) => {
  const { headers, ...restConfig } = config || {}
  const services = useServices()
  const { data, ...context } = useQuery<TQueryData, Error, TData, QueryKeyT>({
    queryKey: [url!, params],
    queryFn: ({ queryKey, signal }) =>
      fetcher<TQueryData>(
        { queryKey, meta: undefined, headers, signal },
        services
      ),
    gcTime: CACHE_TIME,
    staleTime: STALE_TIME,
    throwOnError: (error: any) => isAuthorizationError(error),
    retry: (failureCount, error) => {
      if (isAuthorizationError(error)) return false
      return failureCount < 3
    },
    ...restConfig
  })

  useEffect(() => {
    const { error, isError } = context

    if (!isError || !error) return

    const { logger } = services
    const customError = ERROR_EVENTS.NETWORK.ERROR_RECEIVING_DATA_FROM_NETWORK
    const { status, statusText, message = customError } = (error as any) || {}

    if (!isAuthorizationError(error))
      messageToast.error(error?.message || statusText || message)

    logger.error(error, {
      url,
      params,
      extra: {
        params,
        statusText
      },
      tags: {
        url,
        status,
        custom_error: customError
      },
      fingerprint: [message, "useHttp"]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isError])

  return { response: data, ...context }
}

export const useParallelQueries = <T = unknown>(
  urls: (
    | string
    | { url: string; params: Record<string, unknown>; enabled?: boolean }
  )[]
) => {
  const services = useServices()

  const context = useQueries({
    queries: urls.map<UseQueryOptions<T, Error>>((value) => {
      const isValueString = typeof value === "string"
      const params = isValueString ? {} : value.params
      const url = isValueString ? value : value.url
      const enabled = isValueString || value.enabled !== false ? true : false

      return {
        staleTime: STALE_TIME,
        gcTime: CACHE_TIME,
        queryKey: [url, params],
        queryFn: ({ signal }) =>
          fetcher(
            { signal, queryKey: [url, params], meta: undefined },
            services
          ),
        enabled
      }
    })
  })

  return [
    context,
    { isLoading: context.some((query) => query.isLoading) }
  ] as const
}

const useGenericMutation = <T, S>(
  mutationFn: (data: T | S) => Promise<T>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S, previousData: T) => T) | undefined,
  getOptions?: GetUseMutationOptions<T, S>
) => {
  const queryClient = useQueryClient()

  return useMutation<T, Error, T | S>({
    mutationFn,
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: [url!, params] })

      const previousData = queryClient.getQueryData([url!, params])

      queryClient.setQueryData<T>([url!, params], (oldData) => {
        return updater
          ? updater(oldData!, data as S, previousData as T)
          : (oldData as T)
      })

      return { previousData }
    },
    onError: (_err, _, context: any) => {
      queryClient.setQueryData([url!, params], context?.previousData)
    },
    onSettled: () => {
      return queryClient.invalidateQueries({ queryKey: [url!, params] })
    },
    ...getOptions?.(queryClient)
  })
}

export const useUpdate = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
  options?: GetUseMutationOptions<T, S>
) => {
  const { http } = useServices()

  return useGenericMutation<T, S>(
    (data) => {
      const { needle, data: body } = (data || {}) as any

      if (needle && body) {
        return http.put(`${url}${needle}`, body)
      }

      return http.put(url, data)
    },
    url,
    params,
    updater,
    options
  )
}

export const usePatch = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
  options?: GetUseMutationOptions<T, S>
) => {
  const { http } = useServices()

  return useGenericMutation<T, S>(
    (data) => {
      const { needle, data: body } = (data || {}) as any

      if (needle && body) {
        return http.patch(`${url}${needle}`, body)
      }

      return http.patch(url, data)
    },
    url,
    params,
    updater,
    options
  )
}

export const useCreate = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
  options?: GetUseMutationOptions<T, S>
) => {
  const { http } = useServices()

  return useGenericMutation<T, S>(
    (data) => {
      const { needle, data: body } = (data || {}) as any

      if (needle && body) {
        return http.post(`${url}${needle}`, body)
      }

      return http.post(url, data)
    },
    url,
    params,
    updater,
    options
  )
}

export const useDelete = <T, S = T>(
  url: string,
  params?: object,
  updater?: Updater<T, S>,
  options?: GetUseMutationOptions<T, S>
) => {
  const { http } = useServices()

  return useGenericMutation<T, S>(
    (needle?: any) => http.delete(`${url}${needle ? `/${needle}` : ""}`),
    url,
    params,
    updater,
    options
  )
}

export const useLoadMore = <TData>(
  url: string,
  params: { [x: string]: string },
  config: ConfigLoadMore<TData> = { initialPageParam: 0 }
) => {
  const { http } = useServices()
  const result = useInfiniteQuery({
    staleTime: STALE_TIME,
    gcTime: CACHE_TIME,
    queryKey: [url, JSON.stringify(params)],
    queryFn: ({ pageParam = 0 }) => {
      return http.get(url, {
        params: { pageNumber: `${pageParam}`, pageSize: "20", ...params }
      })
    },
    getNextPageParam: (lastPage) =>
      lastPage?.metadata?.hasNext ? lastPage.metadata.page + 1 : undefined,
    select: ({ pages, pageParams, ...rest }) => ({
      pageParams,
      pages: pages.flatMap((page) => page.data),
      metadata: pages[pages.length - 1]?.metadata,
      ...rest
    }),
    ...config
  })

  return result
}

const includesKeysPredicate =
  (include: KeysPredicate, exclude?: KeysPredicate) => (query: Query) => {
    const queryKey = query?.queryKey?.[0]

    if (typeof queryKey === "string") {
      const excludeCondition =
        exclude?.every((key) => !queryKey.includes(key)) ?? true

      return excludeCondition && include.every((key) => queryKey.includes(key))
    }

    return false
  }

export const predicateHelpers = {
  includesKeysPredicate
}
