import { Carousel } from "@/atoms"
import { RESOURCES_NAMES } from "@/const/resource.constants"
import { TEASERS } from "@/const/teaser.constants"
import { useParallelQueries } from "@/hooks/useHttp"
import { useTranslation } from "@/hooks/useTranslation"
import { VulnerableFunctionSlide } from "@/pages/DashboardPage/components/molecules/VulnerableFunctionSlide"
import CardDashboardTemplate from "@/pages/DashboardPage/components/templates/CardDashboardTemplate"

import styles from "./VulnerableFunctionInRuntimeWidget.module.scss"

export const VulnerableFunctionInRuntimeWidget = () => {
  const { t } = useTranslation()
  const [context, { isLoading }] = useParallelQueries<{ data: number }>(
    ["YES", "", ""].map((runtime) => ({
      url: `${RESOURCES_NAMES.TRIAGE.ISSUES_COUNT}`,
      params: { hasFunctionEvidence: "YES", ...(runtime ? { runtime } : {}) }
    }))
  )

  const executed = context[0].data?.data || 0
  const notExecuted = context[1].data?.data || 0
  const all = context[2].data?.data || 0

  return (
    <CardDashboardTemplate
      title={t("Vulnerable Functions in Runtime")}
      contentPopover={t(
        "Function-level insights support Java, JavaScript and Ruby for select CVEs."
      )}
      loading={isLoading}
      teasers={{
        dataReadiness: true,
        scmInstalled: TEASERS.SCM_INSTALLED.codeRepoNotConnected(t),
        kortexInstalled: TEASERS.KORTEX_INSTALLED.noActiveSensorsDeployed(t)
      }}
    >
      <div className={styles.wrapper}>
        <Carousel>
          <div className={styles.slide}>
            <VulnerableFunctionSlide
              executed
              to="/triage/issues?issueStatus=open&runtime=YES&pageNumber=0&hasFunctionEvidence=YES"
              value={executed}
              suffix={Math.round((executed / all) * 100)}
            />
          </div>
          <div className={styles.slide}>
            <VulnerableFunctionSlide
              value={notExecuted}
              suffix={Math.round((notExecuted / all) * 100)}
            />
          </div>
        </Carousel>
      </div>
    </CardDashboardTemplate>
  )
}
