import { Button, Empty } from "@/atoms"
import { useTranslation } from "@/hooks/useTranslation"
import { LinkButton } from "@/molecules/LinkButton"

import styles from "./EmptyTable.module.scss"

import type { CreateActionEmptyTableProps } from "."

export const CreateActionEmptyTable = (props: CreateActionEmptyTableProps) => {
  const { t } = useTranslation("translation")
  const { to, onClick, image, tKey } = props
  const key = `general.${tKey}`
  const keyPrefix = "filter.emptyState.create."
  const {
    message = t(`${keyPrefix}message`, { name: t(key, { count: 2 }) }),
    action = t(`${keyPrefix}action`, { name: t(key, { count: 1 }) })
  } = props

  return (
    <Empty
      className={styles.containerCreateAction}
      image={image || Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <>
          <span>{message}</span>
          {!!to && (
            <LinkButton type="link" to={to}>
              {action}
            </LinkButton>
          )}
          {!!onClick && (
            <Button type="link" onClick={onClick}>
              {action}
            </Button>
          )}
        </>
      }
    />
  )
}
