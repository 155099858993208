import { isDataAvailable } from "@/helpers/context.helper"
import { buildQueryStringFromRecord } from "@/helpers/filters.helper"
import { useTranslation } from "@/hooks/useTranslation"
import { SbomFilter } from "@/organisms/Drawers/components/SbomFilter"
import { TabDrawerTemplate } from "@/organisms/Drawers/components/TabDrawerTemplate"
import { PACKAGE_DRAWER_ID } from "@/organisms/Drawers/const"
import { useCounts } from "@/organisms/Drawers/hooks/useCounts"
import { useDrawer } from "@/organisms/Drawers/hooks/useDrawer"
import { useParamsFilter } from "@/organisms/Drawers/hooks/useParamsFilter"
import { useSbomInventory } from "@/pages/DiscoveryPage/application/sbom-inventory/useSbomInventory"
import { getColumnsTable } from "./getColumnsTable"
import { getFilters } from "./getFilters"

export const SbomTab = (props: any) => {
  const { t } = useTranslation()
  const filterItems = getFilters(t)
  const tabKey = "sbom"
  const filtersContext = useParamsFilter({
    filterItems,
    filterKey: tabKey,
    initialParams: { pageNumber: "0" },
    ...props
  })
  const { record } = props
  const { id: resourceId } = record || {}
  const enabled = !!resourceId
  const params = { resourceId, ...filtersContext.params }
  const [context, contextCount] = useSbomInventory(params, { enabled })
  const { onRowClick } = useDrawer(PACKAGE_DRAWER_ID)

  useCounts(tabKey, contextCount, props)

  return (
    <TabDrawerTemplate
      expandable={null}
      forceRender={props.forceRender}
      dataContext={context}
      filtersContext={filtersContext}
      getColumnsTable={getColumnsTable}
      onRow={(record) => ({
        onClick: () => onRowClick(record)
      })}
      FilterComponent={
        <SbomFilter
          useQueryString={false}
          items={filterItems}
          isLoading={context.isLoading}
          exportType="SbomDrawer"
          isDataAvailable={isDataAvailable(context)}
          exportQueryParams={() => buildQueryStringFromRecord(params)}
          {...filtersContext}
        />
      }
    />
  )
}
