export const TYPE_DEPLOY = {
  KUBERNETES: "kubernetes",
  GKE: "gke",
  AKS: "aks",
  EKS: "eks",
  EC2: "ec2",
  ECS: "ecs",
  ECS_FARGATE: "ecs-fargate",
  EKS_FARGATE: "eks-fargate"
}

export const DEFAULT_DOWNLOAD_URL = "/api/v1/deployments/download"

export const REGISTRY_CREDENTIALS_DOWNLOAD_URL =
  "/api/v1/deployments/registry-credentials"

export const REGISTRY_FILENAME = "kortex-regcred"

export const REGCRED_YAML_FILENAME = `${REGISTRY_FILENAME}.yaml`

export const REGCRED_CF_YAML_FILENAME = `${REGISTRY_FILENAME}-cf.yaml`

export const DEFAULT_VALUES = {
  ENVIRONMENT: "ENVIRONMENT",
  DEPLOYMENT_KEY: "DEPLOYMENT_KEY",
  STACK_NAME: "STACK_NAME",
  CLOUDFORMATION_FILE: "CLOUDFORMATION_FILE",
  WORKDIR: "WORKDIR",
  EFS_VOLUME_HANDLE: "EFS_VOLUME_HANDLE",
  AWS_ROLE_ARN: "AWS_ROLE_ARN",
  CLUSTER_ARN: "CLUSTER_ARN",
  SECRET_NAME: "SECRET_NAME",
  KORTEX_SUBNET: "KORTEX_SUBNET",
  KORTEX_SECURITY_GROUP: "KORTEX_SECURITY_GROUP",
  KORTEX_TGZ_PATH: "KORTEX_TGZ_PATH",
  NAMESPACE: "NAMESPACE",
  SECRET_ARN: "SECRET_ARN"
}
