import { useGetInventorySnapshot } from "@/api/useInventory"
import { useGetStatistics } from "@/api/useStatistics"
import { useFeatureFlags } from "@/hooks/useUser"
import { useFunnelTriage } from "@/pages/TriagePage/components/organisms/FunnelTriagePage"
import { snapshotInventoryPropsSelector } from "../selectors/inventory.selector"
import { statisticsSelector } from "../selectors/statistics.selector"

import type { DashboardProps } from "./useDashboard.types"

const useDashboard = (): DashboardProps => {
  const postureResponse = useGetStatistics(
    { needle: "/posture_score" },
    { select: statisticsSelector }
  )

  const kortexInstalledFF = useFeatureFlags("kortexInstalled")
  const {
    isLoading: isLoadingExploitableIssues,
    ...exploitableIssuesResponse
  } = useGetStatistics(
    {
      needle: kortexInstalledFF
        ? "/runtime_exploitable_issues"
        : "/exploitable_issues"
    },
    { select: statisticsSelector, enabled: kortexInstalledFF !== undefined }
  )

  const inventorySnapshotResponse = useGetInventorySnapshot(undefined, {
    select: snapshotInventoryPropsSelector
  })

  const funnelContext = useFunnelTriage()
  const runtime = kortexInstalledFF
  const requestsFunnel = funnelContext?.[0]
  const isLoadingFunnel = requestsFunnel?.[1]?.isLoading
  const exploitableFunnel = requestsFunnel?.[0]?.[4]?.data?.data || 0 // 4 is the index of the exploitable issues in the funnel, uses in the ExploitableIssuesOverTimeWidget

  return {
    runtime,
    postureProps: postureResponse,
    inventorySnapshotProps: inventorySnapshotResponse,
    funnelProps: { context: funnelContext },
    exploitableProps: {
      exploitableFunnel,
      isLoading: isLoadingExploitableIssues || isLoadingFunnel,
      ...exploitableIssuesResponse
    }
  }
}

export default useDashboard
