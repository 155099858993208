import cn from "classnames"

import styles from "./DeployTab.module.scss"

import type { TitleStepDeployTabComponent } from "."

export const TitleStepDeployTab: TitleStepDeployTabComponent = ({
  children,
  subTitle,
  prefix
}) => (
  <div className={cn(subTitle && styles.containerTitleStep)}>
    <span className={styles.titleStep}>
      <span className={styles.prefixTitleStep}>{prefix}</span>
      {children}
    </span>
    {subTitle && <span className={styles.subtitleStep}>{subTitle}</span>}
  </div>
)
