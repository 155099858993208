import { useEffect, useState } from "react"

import { RESOURCES_NAMES } from "@/const/resource.constants"
import { useParallelQueries } from "@/hooks/useHttp"
import { getIssuesDefaultParams } from "./helpers"

import type { Type } from "@/domain/issue"
import type { Severity } from "@/domain/vulnerability"

export const useIssuesBySeverity = (
  issueTypes: Type[],
  props: { runtime?: boolean }
) => {
  const severity: Severity[] = ["critical", "high", "medium"]
  const enabled = props.runtime !== undefined
  const getUrls = () => {
    const urlsCounts: {
      url: string
      params: Record<string, string>
      enabled: boolean
    }[] = issueTypes.flatMap((issueType) =>
      severity.map((severity) => ({
        url: RESOURCES_NAMES.TRIAGE.ISSUES_COUNT,
        params: { severity, issueType, ...getIssuesDefaultParams() },
        enabled
      }))
    )

    urlsCounts.push({
      url: RESOURCES_NAMES.TRIAGE.ISSUES_COUNT,
      params: {},
      enabled
    })

    if (!props.runtime) return urlsCounts

    return urlsCounts.map((url) => {
      if (url.params.issueType === "Vulnerable_Package") {
        return {
          ...url,
          params: { ...url.params, runtime: "YES" }
        }
      }

      return url
    })
  }

  const urls = getUrls()

  const [data, { isLoading }] =
    useParallelQueries<KosmosApi.Response<number>>(urls)
  const [values, setValues] = useState<{
    response: { data: number[][] }
    isLoading: boolean
    isRuntime: boolean
  }>({ response: { data: [] }, isLoading: true, isRuntime: false })

  useEffect(() => {
    if (isLoading || !enabled) return

    const result = data.map((item) => item.data?.data || 0)

    const currentData = issueTypes.map(() => {
      const nextValue = result.splice(0, 3)

      return [...nextValue, nextValue.reduce((acc, value) => acc + value, 0)]
    })

    setValues({
      response: { data: currentData },
      isLoading: false,
      isRuntime: props.runtime || false
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return [values, isLoading] as const
}
