import cn from "classnames"

import { BetaLabel, Col, Text } from "@/atoms"
import { isPackageIssueType } from "@/domain/issue"
import { useLogger } from "@/hooks/useLogger"
import { useTranslation } from "@/hooks/useTranslation"
import { Heading } from "@/molecules/Heading"
import { InformationPopover } from "@/molecules/InformationPopover"
import { CollapseDrawer } from "@/organisms/Drawers/components/CollapseDrawer"
import { FunctionLevel } from "./components/FunctionLevel"
import { PackageRuntimeEvidence } from "./components/PackageRuntimeEvidence"

import type { RuntimeEvidenceProps } from "./RuntimeEvidence.types"

import styles from "./RuntimeEvidence.module.scss"

export const RuntimeEvidence = (props: RuntimeEvidenceProps) => {
  const { t } = useTranslation()
  const { t: tRuntimeEvidence } = useTranslation("translation", {
    keyPrefix: "runtimeEvidence"
  })
  const { logger, EVENTS } = useLogger()
  const { record, isCard } = props

  if (!record || !record.riskInsights) {
    return null
  }

  const { riskInsights, issueType } = record
  const { runtime } = riskInsights

  if (!isPackageIssueType(issueType)) return null

  const title = runtime ? t("general.inRuntime") : t("general.notInRuntime")
  const titleClassName = runtime ? "ks-color-text" : ""

  // the terminology of classes is general and can be used for any language - but in classless languages it will be files

  return (
    <Col span={24}>
      <CollapseDrawer
        classes={{
          container: isCard
            ? cn(styles.collapseContainer, styles.isCardContainer)
            : cn(
                "margin-horizontal-container-items-issue-expanded-cell-component-issue-tab",
                styles.collapseContainer
              )
        }}
        key="runtime-evidence"
        header={
          <div className={styles.collapseHeaderContainer}>
            <Heading
              level={6}
              classes={{ title: titleClassName }}
              popover={{
                className: styles.popover,
                content: (
                  <InformationPopover.CommonTemplate>
                    <Text strong className={styles.textPopover}>
                      {tRuntimeEvidence("titlePopover")}
                    </Text>
                    <br />
                    {tRuntimeEvidence("contentPopover")}
                  </InformationPopover.CommonTemplate>
                )
              }}
              title={title}
            />
            <BetaLabel />
          </div>
        }
        onChange={(keys) => {
          if (!!keys?.length)
            logger.info(
              EVENTS.ANALYTIC_EVENTS.TRIAGE.DRAWER.IN_RUNTIME_COLLAPSE_OPEN
            )
        }}
      >
        <PackageRuntimeEvidence record={record} />
        <FunctionLevel record={record} />
      </CollapseDrawer>
    </Col>
  )
}
