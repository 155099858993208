import { CellTable } from "@/atoms"
import { ISSUES_LIMIT } from "@/const/table-constants"
import {
  getDefaultColumnParams,
  preventDisplayTitleOnCell
} from "@/helpers/table.helpers"
import { NumberConvertor } from "@/hooks/useNumberConvertor"
import { ImpactCell } from "@/molecules/ImpactCell"
import { IssuesSummaryCell } from "@/molecules/IssuesSummaryCell"
import { DigestImageCell } from "../../molecules/DigestImageCell"
import { ImageActionsCell } from "../../molecules/ImageActionsCell"
import { ImageNameCell } from "../../molecules/ImageNameCell"
import { InsightsImageCell } from "../../molecules/InsightsImageCell"
import { MoreTableCellDrawer } from "../../molecules/MoreTableCellDrawer"

import type { SortOrder } from "@/atoms"
import type { RowClick } from "@/hooks/useLinkDrawerTooltip"
import type { ORDER_BY_PROPS } from "@/hooks/useOrderBy"
import type { TFunction } from "@/hooks/useTranslation"

export const getPreviewColumnsTable = (
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">
) => [
  {
    title: "Image Name",
    className: "displayName-image-tab-template-component",
    render: ImageNameCell,
    onCell: preventDisplayTitleOnCell,
    width: "20%",
    ...getDefaultColumnParams("displayName", sorter),
    sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
  },
  {
    title: "Tag",
    width: "15%",
    render: CellTable,
    ...getDefaultColumnParams("tag")
  }
]

export const getColumnsTable = ({
  sorter,
  t,
  convertors,
  previewMode,
  onRowClick
}: {
  sorter: Omit<ORDER_BY_PROPS, "onSorterChange">
  t: TFunction
  convertors: NumberConvertor
  onRowClick: RowClick
  previewMode?: boolean
}) => {
  const previewColumns = getPreviewColumnsTable(sorter)

  if (previewMode) return previewColumns

  return [
    ...previewColumns,
    {
      title: "OS",
      width: 80,
      className: "capitalize-tab-template-component",
      render: CellTable,
      ...getDefaultColumnParams("operatingSystems")
    },
    {
      title: "Digest",
      width: 110,
      render: DigestImageCell,
      ...getDefaultColumnParams("digestShort")
    },
    {
      title: "Insights",
      render: InsightsImageCell(t),
      width: 70,
      ...getDefaultColumnParams("insights")
    },
    {
      title: "Issues Summary",
      width: 220,
      render: IssuesSummaryCell({ t, convertors }, ISSUES_LIMIT),
      ...getDefaultColumnParams("severity", sorter),
      defaultSortOrder: "descend" as SortOrder,
      sortDirections: ["descend", "ascend", "descend"] as SortOrder[]
    },
    {
      title: "Apps",
      width: 70,
      className: "center-align-tab-template-component",
      render: ImpactCell({ onRowClick }),
      sortDirections: ["descend", "ascend"] as SortOrder[],
      ...getDefaultColumnParams("applicationsCount", sorter)
    },
    {
      title: "Code Repositories",
      width: "18%",
      render: MoreTableCellDrawer(onRowClick),
      ...getDefaultColumnParams("codeRepositoriesDisplayValue")
    },
    {
      title: "SBOM",
      width: 55,
      className: "center-align-tab-template-component",
      ...getDefaultColumnParams("sbomQuery"),
      render: (_: any, record: any) => {
        return ImageActionsCell(record)
      }
    },
    {
      title: "First Seen",
      width: 155,
      ...getDefaultColumnParams("firstSeen")
    }
  ]
}
