import { LANGUAGES } from "@/const/language.constants"
import { INEQUALITY_SYMBOL } from "@/organisms/SmartSearch/const/symbol.constants"

import type { TFunction } from "@/hooks/useTranslation"
import type { OptionFilter } from "@/organisms/SmartSearch/types/options.types"

export const getIssueTypeFilter = (t: TFunction): OptionFilter => ({
  key: "issueType",
  label: t("filters.issueType"),
  type: "multiple",
  helper: [
    {
      label: "issue-type:value1,value2",
      description: "Equals value1 OR value2"
    }
  ],
  options: []
})

export const getDismissReasonFilter = (t: TFunction): OptionFilter => ({
  key: "dismissReason",
  label: t("filters.dismissReason"),
  type: "input",
  helper: [
    {
      label: "Dismiss-Reason: value1",
      description:
        'Matches Dismiss Reason text as a substring (e.g. "mitigated by")'
    }
  ]
})

export const getLanguagesFilter = (t: TFunction): OptionFilter => ({
  key: "language",
  label: t("filters.languages"),
  type: "multiple",
  options: getLanguageFilterOptions(),
  helper: [
    {
      label: t("language:value1,value2", { label: t("filters.languages") }),
      description: "Equals value1 OR value2"
    }
  ]
})

export const getHasFunctionEvidenceFilter = (t: TFunction): OptionFilter => ({
  key: "hasFunctionEvidence",
  label: t("filters.hasFunctionEvidence"),
  type: "single",
  options: [
    { label: t("general.yes"), value: "YES" },
    { label: t("general.no"), value: "NO" }
  ]
})

export const getLablesFilter = (t: TFunction): OptionFilter => ({
  key: "label",
  label: t("filters.labels"),
  linkedKey: "label",
  condition: "AND",
  type: "multiple",
  helper: [
    {
      label: t("filters.helpers.multipleValues", {
        label: t("filters.labels")
      }),
      description: "Equals value1 OR value2"
    }
  ],
  options: []
})

export const getNotLablesFilter = (t: TFunction): OptionFilter => ({
  key: "notLabel",
  label: `${INEQUALITY_SYMBOL}${t("filters.labels")}`,
  type: "multiple",
  linkedKey: "label",
  inequality: true,
  helper: [
    {
      label: `${INEQUALITY_SYMBOL}${t("filters.helpers.multipleValues", {
        label: t("filters.labels")
      })}`,
      description: "Not equals value1 OR value2"
    }
  ],
  options: []
})

export const getJiraIssueIdFilter = (t: TFunction): OptionFilter => ({
  key: "jiraIssueId",
  label: t("filters.jiraIssueId").toLowerCase(),
  displayLabel: t("filters.jiraIssueId"),
  type: "input",
  helper: [
    {
      label: t("filters.helpers.multipleValues", {
        label: t("filters.jiraIssueId")
      }),
      description: "Matches external IDs as substring (e.g. kod-11, kod23)"
    }
  ]
})

export const getOsFilter = (t: TFunction): OptionFilter => ({
  key: "os",
  label: t("filters.os").toLowerCase(),
  displayLabel: t("filters.os"),
  type: "input",
  helper: [
    {
      label: t("filters.helpers.multipleValues", { label: t("filters.os") }),
      description: t("filters.helpers.multipleSubstring", {
        label: "operating systems"
      })
    }
  ]
})

export const buildQueryStringFromRecord = (
  params: Record<string, any>
): string => {
  const query = new URLSearchParams(params)

  return query.toString()
}

export const getLanguageFilterOptions = () =>
  LANGUAGES.map((value) => ({ label: value, value }))
