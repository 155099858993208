import { Form, Input, type Rule } from "@/atoms"

import type { FormWebhookProps } from "."

export const FormWebhook = (props: FormWebhookProps) => {
  const { form, formId, initialValues, onFieldsChange, onFinish, t } = props
  const rules = [{ required: true, message: t("error.requiredField") }]
  const urlRules = [
    { required: true, type: "url", message: t("error.invalidURL") } as Rule
  ]
  const prefix = "integrations.webhook.form"

  return (
    <Form
      form={form}
      id={formId}
      layout="vertical"
      initialValues={initialValues}
      onFieldsChange={onFieldsChange}
      onFinish={onFinish}
    >
      <Form.Item
        label={t("general.name")}
        name="name"
        required={false}
        rules={rules}
      >
        <Input
          ref={props.focusRef}
          placeholder={t(`${prefix}.name.placeholder`)}
        />
      </Form.Item>
      <Form.Item label={t(`${prefix}.description.label`)} name="description">
        <Input placeholder={t(`${prefix}.description.placeholder`)} />
      </Form.Item>
      <Form.Item
        label={t(`${prefix}.url.label`)}
        name="url"
        required={false}
        rules={urlRules}
      >
        <Input placeholder="e.g https://webhook.com/endpoint" />
      </Form.Item>
      <Form.Item label={t(`${prefix}.secret.label`)} name="secret">
        <Input placeholder={t(`${prefix}.secret.placeholder`)} />
      </Form.Item>
    </Form>
  )
}
